import React, { createContext, useState } from 'react';

export const DataContext = createContext(null);

export const DataProvider = (props) => {
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [address, setAddress] = useState('');
    const [billingMethodName, setBillingMethodName] = useState('');
    const [currentBillingValue, setCurrentBillingValue] = useState('');
    const [deliveryMethodName, setDeliveryMethodName] = useState('');
    const [currentDeliveryValue, setCurrentDeliveryValue] = useState('');
    const [shippingMethodName, setShippingMethodName] = useState('');
    const [currentShippingValue, setCurrentShippingValue] = useState('');
    const [currentClientValue, setCurrentClientValue] = useState('');
    const [currentClientName, setCurrentClientName] = useState('');
    const [edit, setEdit] = useState();

    return (
        <DataContext.Provider
            value={{
                deliveryAddress,
                setDeliveryAddress,
                address,
                setAddress,
                deliveryMethodName,
                setDeliveryMethodName,
                billingMethodName,
                setBillingMethodName,
                currentBillingValue,
                setCurrentBillingValue,
                currentDeliveryValue,
                setCurrentDeliveryValue,
                currentShippingValue,
                setCurrentShippingValue,
                shippingMethodName,
                setShippingMethodName,
                currentClientValue,
                setCurrentClientValue,
                currentClientName,
                setCurrentClientName,
                edit,
                setEdit,
            }}
        >
            {props.children}
        </DataContext.Provider>
    );
};
