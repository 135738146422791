import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonLoading } from '../shared/CustomButton';
import { updatePassword } from '../../services/user/credentials';
import { sendMail } from '../../services/mail/credentialsMail';
import { LinkForm } from '../shared/LinkForm';
import { SpinnerBtn } from '../shared/Spinner';
import Error from '../shared/Error';
import { Bar, Label } from '../shared/CustomInput';
import { Form, Input, Row, Element, LocalError, LabelContainer } from '../../assets/css/_forms';
import { COLORS } from '../../assets/css/_colors';
import loginPasswordIcon from '../../assets/images/login-lock.svg';

export const ChangePassword = ({}) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const userId = JSON.parse(localStorage.getItem('userId'));
  const [error, setError] = useState('');
  const [passwordOldCSS, setPasswordOldCSS] = useState({ top: '30px' });
  const [passwordNewCSS, setPasswordNewCSS] = useState({ top: '30px' });
  const [passwordConfirmCSS, setPasswordConfirmCSS] = useState({
    top: '30px',
  });
  const { register, handleSubmit, setValue, errors, watch } = useForm();
  const [loading, setLoading] = useState(false);

  const onChangePasswordSubmit = async (data) => {
    if (watch('passwordNew') !== watch('passwordConfirm')) {
      setValue('passwordNew', '');
      setValue('passwordConfirm', '');
      setError('Lozinka i potvrđena lozinka moraju biti identične!');
    } else if (watch('passwordNew') === watch('passwordConfirm') && watch('passwordNew') === watch('passwordOld')) {
      setValue('passwordOld', '');
      setValue('passwordNew', '');
      setValue('passwordConfirm', '');
      setError('Novo unesena lozinka je identična staroj, ako želite promijeniti lozinku unesite neku drugu!');
    } else {
      try {
        setLoading(true);
        await updatePassword(token, userId, data);
        await sendMail(userId);
        localStorage.setItem('actionMessage', 'Uspješna promjena lozinke!');
        window.location.reload(false);
      } catch (e) {
        setValue('passwordOld', '');
        setValue('passwordNew', '');
        setValue('passwordConfirm', '');
        setError(e.Message);
        setLoading(false);
      }
    }
  };

  return (
    <Form autocomplete="off" style={{ width: '250px' }} onSubmit={handleSubmit(onChangePasswordSubmit)}>
      <Row style={{ gridTemplateColumns: '100%' }}>
        <Element
          width="100%"
          onBlur={() =>
            watch('passwordOld') === ''
              ? setPasswordOldCSS({
                  top: '30px',
                })
              : setPasswordOldCSS({
                  top: '0px',
                })
          }
          onFocus={() => setPasswordOldCSS({ top: '0px' })}
        >
          <Input
            autoFocus
            ref={register({
              required: {
                value: true,
                message: 'Unesite lozinku',
              },
              maxLength: {
                value: 25,
                message: 'Lozinka smije imati najviše 25 znakova',
              },
              minLength: {
                value: 6,
                message: 'Lozinka mora imati najmanje 6 znakova',
              },
            })}
            name="passwordOld"
            type="password"
            onChange={(e) => setError('')}
          />
          <Bar />
          <LabelContainer style={passwordOldCSS}>
            <img
              style={{
                transition: 'all 0.2s ease',
                width: '15px',
                marginRight: '10px',
              }}
              src={loginPasswordIcon}
              alt="login"
            />
            <span>Lozinka</span>
          </LabelContainer>
          {errors.passwordOld && <LocalError>{errors.passwordOld.message}</LocalError>}
        </Element>
      </Row>

      <Row style={{ gridTemplateColumns: '100%' }}>
        <Element
          width="100%"
          onBlur={() =>
            watch('passwordNew') === ''
              ? setPasswordNewCSS({
                  top: '30px',
                })
              : setPasswordNewCSS({
                  top: '0px',
                })
          }
          onFocus={() => setPasswordNewCSS({ top: '0px' })}
        >
          <Input
            ref={register({
              required: {
                value: true,
                message: 'Unesite novu lozinku',
              },
              maxLength: {
                value: 25,
                message: 'Lozinka ne smije imati najviše 25 znakova',
              },
              minLength: {
                value: 6,
                message: 'Lozinka mora imati najmanje 6 znakova',
              },
            })}
            name="passwordNew"
            type="password"
            onChange={(e) => setError('')}
          />
          <Bar />
          <LabelContainer style={passwordNewCSS}>
            <img
              style={{
                transition: 'all 0.2s ease',
                width: '15px',
                marginRight: '10px',
              }}
              src={loginPasswordIcon}
              alt="login"
            />
            <span>Nova lozinka</span>
          </LabelContainer>
          {errors.passwordNew && <LocalError>{errors.passwordNew.message}</LocalError>}
        </Element>
      </Row>

      <Row style={{ gridTemplateColumns: '100%' }}>
        <Element
          width="100%"
          onBlur={() =>
            watch('passwordConfirm') === ''
              ? setPasswordConfirmCSS({
                  top: '30px',
                })
              : setPasswordConfirmCSS({
                  top: '0px',
                })
          }
          onFocus={() => setPasswordConfirmCSS({ top: '0px' })}
        >
          <Input
            ref={register({
              required: {
                value: true,
                message: 'Unesite lozinku',
              },
              maxLength: {
                value: 25,
                message: 'Lozinka smije imati najviše 25 znakova',
              },
              minLength: {
                value: 6,
                message: 'Lozinka mora imati najmanje 6 znakova',
              },
            })}
            name="passwordConfirm"
            type="password"
            onChange={(e) => setError('')}
          />
          <Bar />
          <LabelContainer style={passwordConfirmCSS}>
            <img
              style={{
                transition: 'all 0.2s ease',
                width: '15px',
                marginRight: '10px',
              }}
              src={loginPasswordIcon}
              alt="login"
            />
            <span>Potvrdite novu lozinku</span>
          </LabelContainer>
          {errors.passwordConfirm && <LocalError>{errors.passwordConfirm.message}</LocalError>}
        </Element>
      </Row>

      <ButtonLoading
        style={
          loading
            ? {
                backgroundColor: 'transparent',
                color: `${COLORS.green}`,
                border: `2px solid ${COLORS.green}`,
                paddingTop: '0',
              }
            : null
        }
        type="submit"
      >
        {loading && <SpinnerBtn />}
        Spremi
      </ButtonLoading>

      <LinkForm to={'/credentials/recover'} text={'Zaboravili ste lozinku?'} linkText={'Klikni ovdje!'} />

      {error !== '' && <Error msg={error} />}
    </Form>
  );
};
