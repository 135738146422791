import { API_URL } from '../constants/index';

export async function getShippingMethods(token, userId) {
    try {
        const res = await fetch(`${API_URL}/shippingMethods`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            const response = {
                Message: json.Message,
                Status: res.status,
            };
            throw response;
        }
    } catch (e) {
        throw e;
    }
}

export async function getBillingMethods(token, userId) {
    try {
        const res = await fetch(`${API_URL}/billingMethods`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            const response = {
                Message: json.Message,
                Status: res.status,
            };
            throw response;
        }
    } catch (e) {
        throw e;
    }
}

export async function getDeliveryMethods(token, userId) {
    try {
        const res = await fetch(`${API_URL}/deliveryMethods`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            const response = {
                Message: json.Message,
                Status: res.status,
            };
            throw response;
        }
    } catch (e) {
        throw e;
    }
}

export async function getDetailStatusList(token, userId) {
    try {
        const res = await fetch(`${API_URL}/issue/detail/status`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            const response = {
                Message: json.Message,
                Status: res.status,
            };
            throw response;
        }
    } catch (e) {
        throw e;
    }
}
