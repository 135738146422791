import React from 'react';
import styled from 'styled-components';
import { RegisterForm } from '../Forms/RegisterForm';
import { COLORS } from '../../assets/css/_colors';

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    background: ${COLORS.grayLightBackground};
    position: absolute;
    z-index: 1;
    padding: 10px;
`;

const Container = styled.div`
    max-width: 800px;
    margin: 0 auto;
    transition: all 1s ease;
    padding: 0 40px;

    @media (max-width: 480px) {
        padding: 0 20px;
    }
`;

const Title = styled.h1`
    text-align: center;
    margin-top: 0;
    padding-top: 20px;
    color: ${COLORS.grayDark};
`;

const RegisterInfodata = (props) => {
    return (
        <Wrapper>
            <Container>
                <Title>Registracija</Title>
                <RegisterForm access={true} />
            </Container>
        </Wrapper>
    );
};

export default RegisterInfodata;
