import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { getCurrentDate } from '../../../utils/getCurrentDate';
import MasterInfoEditData from './MasterInfoEditData';
import { COLORS } from '../../../assets/css/_colors';

const Container = styled.div`
    width: 100%;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 50%);
    margin: 15px 0;

    @media (max-width: 767px) {
        grid-template-columns: 100%;
    }
`;

const Element = styled.data`
    width: ${(props) => props.width};
    position: relative;
    padding: 15px 0;
    border-bottom: 1px solid ${COLORS.grayLight};
    color: ${COLORS.green};

    @media (max-width: 767px) {
        width: 100%;
    }
`;

const MasterInfoNew = ({ user, firmName, userType, phone, email }) => {
    return (
        <Container>
            {userType === '2' ? (
                <Row>
                    <Element width='95%'>
                        <label>Prijavio: </label>
                        <label style={{ color: `${COLORS.grayTextLight}` }}>
                            {user}
                        </label>
                    </Element>
                    <Element
                        width='95%'
                        style={{
                            justifySelf: 'end',
                        }}
                    >
                        <label>Datum: </label>
                        <label style={{ color: `${COLORS.grayTextLight}` }}>
                            {getCurrentDate()}
                        </label>
                    </Element>
                </Row>
            ) : (
                <div>
                    <Row>
                        <Element width='95%'>
                            <label>Datum: </label>
                            <label style={{ color: `${COLORS.grayTextLight}` }}>
                                {getCurrentDate()}
                            </label>
                        </Element>
                    </Row>
                    <Row>
                        <Element width='95%'>
                            <label>Naziv firme: </label>
                            <label style={{ color: `${COLORS.grayTextLight}` }}>
                                {firmName}
                            </label>
                        </Element>
                        <Element
                            width='95%'
                            style={{
                                justifySelf: 'end',
                            }}
                        >
                            <label>Prijavio: </label>
                            <label style={{ color: `${COLORS.grayTextLight}` }}>
                                {user}
                            </label>
                        </Element>
                    </Row>
                </div>
            )}

            <Row>
                <Element width='95%'>
                    <label>Telefon: </label>
                    {phone ? (
                        <label style={{ color: `${COLORS.grayTextLight}` }}>
                            {phone}
                        </label>
                    ) : (
                        <label style={{ color: `${COLORS.grayTextLight}` }}>
                            -
                        </label>
                    )}
                </Element>
                <Element
                    width='95%'
                    style={{
                        justifySelf: 'end',
                    }}
                >
                    <label>Email: </label>
                    <label style={{ color: `${COLORS.grayTextLight}` }}>
                        {email}
                    </label>
                </Element>
            </Row>

            <MasterInfoEditData />
        </Container>
    );
};

export default MasterInfoNew;
