import Polyglot from 'node-polyglot';
import LocaleNotInitializedException from './LocaleNotInitializedException';
import MissingLocaleException from './MissingLocaleException';

// global instance
let polyglot: Polyglot;

export default class LocalizeService {
  static initLocalization(locale: string, messages: Record<string, Record<string, string>>) {
    const localeMessages = locale && messages[locale] ? messages[locale] : null;

    if (localeMessages === null) throw new MissingLocaleException(locale);

    polyglot = new Polyglot({ locale, phrases: localeMessages });
  }

  static translate(name: string, params?: Record<string, any>) {
    if (polyglot == null) {
      throw new LocaleNotInitializedException();
    }

    return polyglot.t(name, params || {});
  }
}
