import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from './../../Pages/HomePage';
import Login from './../../Pages/Login';
import Register from './../../Pages/Register';
import RegisterInfodata from './../../Pages/RegisterInfodata';
import Profile from './../../Pages/Profile';
import ClientPage from './../../Pages/ClientPage';
import ClientNewPage from './../../Pages/ClientNewPage';
import EditPage from './../../Pages/EditPage';
import NewPage from './../../Pages/NewPage';
import SavedPage from './../../Pages/SavedPage';
import RecoverCredentials from './../../Pages/RecoverCredentials';
import ScrollToTop from './../../shared/ScrollToTop';
import { ContactPage } from './../../Pages/ContactPage';
import { DataProvider as DataProviderMaster } from './../../../context/MasterDataContext';
import AuthenticationRouteGuard from './AuthenticationRouteGuard';

const appRouter = (
  <Router>
    <ScrollToTop />
    <Switch>
      {/* Bez autorizacije ------------------------------------------------------------------*/}
      <Route path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/register/infodata" component={RegisterInfodata} />
      <Route exact path="/credentials/recover" component={RecoverCredentials} />

      {/* Autorizacija ------------------------------------------------------------------*/}
      <AuthenticationRouteGuard>
        <Route exact path="/profile" component={Profile} />

        <Route exact path="/client/list" component={ClientPage} />
        <Route exact path="/client/new" component={ClientNewPage} />

        <Route exact path="/contact-us" component={ContactPage} />

        {/* PRIJAVA KVARA ------------------------------------------------------------------*/}
        <DataProviderMaster>
          <Route exact path="/" component={HomePage} />

          <Route path="/detail/:year/:number/:id" component={SavedPage} />
          <Route path="/detail/:year/:number" component={NewPage} />
          <Route path="/new/detail" component={NewPage} />
          <Route path="/add/detail/:year/:number" component={NewPage} />
          <Route path="/edit/detail/:year/:number/:id" component={EditPage} />
        </DataProviderMaster>
      </AuthenticationRouteGuard>
    </Switch>
  </Router>
);

export default appRouter;
