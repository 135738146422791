import React, { PropsWithChildren } from 'react';
import ErrorPage from 'src/Components/Pages/ErrorPage';

interface IIBErrorBoundaryOwnProps {
  // ??
}
type IBErrorBoundaryProps = PropsWithChildren<IIBErrorBoundaryOwnProps>;

interface IIBErrorBoundaryState {
  hasError: boolean;
}

/** Error boundary for displaying proper UI feedback when error happens */
class IBErrorBoundary extends React.Component<
  IBErrorBoundaryProps,
  IIBErrorBoundaryState
> {
  state: IIBErrorBoundaryState = {
    hasError: false,
  };

  static getDerivedStateFromError = (error: any) => {
    return { hasError: true };
  };

  componentDidCatch = (error: any, info: any) => {
    // log caught error
    console.error('Boundary error caught', error, info);
  };

  render = () => {
    return this.state.hasError ? <ErrorPage /> : this.props.children;
  };
}

export default IBErrorBoundary;
