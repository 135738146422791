import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import MasterInfoSaved from '../IssueReport/Master/MasterInfoSaved';
import MasterInfoNew from '../IssueReport/Master/MasterInfoNew';
import DetailForm from '../Forms/DetailForm';
import Warning from '../shared/Warning';
import { Spinner } from '../shared/Spinner';
import { DataContext } from '../../context/MasterDataContext';
import 'react-notifications-component/dist/theme.css';
import { Title } from '../../assets/css/_elements';
import { Container } from '../../assets/css/_pages';
import { getMasterInfo } from '../../services/issue/issueMaster';
import { getUserInfo } from '../../services/user/info';
import arrowNextIcon from '../../assets/images/arrow_next.svg';
import PageLayout from '../common/layout/PageLayout';
import Notification from '../shared/Notification';

const NewPage = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  const userId = JSON.parse(localStorage.getItem('userId'));
  const action = localStorage.getItem('action');
  const actionMessage = localStorage.getItem('actionMessage');
  const { year, number } = useParams();
  const [userType, setUserType] = useState('');
  const [status, setStatus] = useState('');
  const [date, setDate] = useState('');
  const [user, setUser] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [firmName, setFirmName] = useState('');
  const [loading, setLoading] = useState(true);
  const {
    setAddress,
    setDeliveryAddress,
    setDeliveryMethodName,
    setBillingMethodName,
    setCurrentBillingValue,
    setCurrentDeliveryValue,
    setEdit,
    setCurrentClientValue,
  } = useContext(DataContext);

  useEffect(() => {
    if (!isNaN(year) && !isNaN(number)) {
      fetchMasterData();
    } else {
      fetchUserData();
    }
  }, []);

  const fetchMasterData = async () => {
    try {
      setEdit(true);
      const data = await getMasterInfo(token, year, number);
      setStatus(data.Status);
      setDate(data.Date);
      setDeliveryAddress(data.DeliveryAddress);
      setAddress(data.Address);
      setDeliveryMethodName(data.DeliveryMethodName);
      setBillingMethodName(data.BillingMethodName);
      setCurrentBillingValue(data.BillingMethod);
      setCurrentDeliveryValue(data.DeliveryMethod);
      setCurrentClientValue(data.UserId);

      const userData = await getUserInfo(token, userId);
      setFirmName(userData.FirmName);
      setPhone(userData.Phone);
      setEmail(userData.Email);
      setUser(userData.FirstName + ' ' + userData.LastName);
      setUserType(data.UserType);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchUserData = async () => {
    try {
      setEdit(false);

      const data = await getUserInfo(token, userId);
      setFirmName(data.FirmName);
      setPhone(data.Phone);
      setEmail(data.Email);
      setUser(data.FirstName + ' ' + data.LastName);
      setUserType(data.Type);
      if (data.Address !== '') {
        setAddress(data.Address + ', ' + data.ZipCode + ', ' + data.City);
      }
      if (data.DeliveryAddress !== '') {
        setDeliveryAddress(
          data.DeliveryAddress +
            ', ' +
            data.DeliveryZipCode +
            ', ' +
            data.DeliveryCity
        );
      } else if (data.DeliveryAddress === '' && data.Address !== '') {
        setDeliveryAddress(
          data.Address + ', ' + data.ZipCode + ', ' + data.City
        );
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageLayout>
      <Container>
        {loading ? (
          <Spinner style={{ marginTop: '100px' }} />
        ) : (
          <div>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <img style={{ marginRight: '20px' }} src={arrowNextIcon} />
              <Title>Prijava kvara</Title>
            </div>
            {year && number && (
              <MasterInfoSaved
                date={date}
                status={status}
                user={user}
                firmName={firmName}
                userType={userType}
                phone={phone}
                email={email}
              />
            )}
            {!year && !number && (
              <MasterInfoNew
                user={user}
                firmName={firmName}
                userType={userType}
                phone={phone}
                email={email}
              />
            )}
            <Warning
              msg={
                'Ako promijenite neke od podataka vezanih uz prijavu kvara, oni će se promijeniti za sve stavke tog kvara!'
              }
            />
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <img style={{ marginRight: '20px' }} src={arrowNextIcon} />
              <Title>Stavka prijavljenog kvara</Title>
            </div>
            <DetailForm edit={false} />
          </div>
        )}
      </Container>
    </PageLayout>
  );
};

export default NewPage;
