import React, { PropsWithChildren } from 'react';
import { AuthProvider } from 'src/context/AuthContext';
import LocaleProvider from './app/LocaleProvider';

/** Main Application shell. Can be used for initializing various resources (ie. context) */
const App: React.FC<PropsWithChildren<any>> = (props) => {
  return (
    <React.Fragment>
      <AuthProvider>
        <LocaleProvider>{props.children}</LocaleProvider>
      </AuthProvider>
    </React.Fragment>
  );
};

export default App;
