import { API_URL } from '../../constants/index';

export async function registerMaster(data) {
  try {
    const res = await fetch(`${API_URL}/issue/master`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Token: data.Token,
        UserId: data.UserId,
      },
      body: JSON.stringify({
        Address: data.Address,
        DeliveryAddress: data.DeliveryAddress,
        DeliveryMethod: data.DeliveryMethod,
        BillingMethod: data.BillingMethod,
        DeliveryMethodName: data.DeliveryMethodName,
        BillingMethodName: data.BillingMethodName,
        ShippingMethod: data.ShippingMethod,
        ShippingMethodName: data.ShippingMethodName,
        ClientId: data.ClientId,
      }),
    });
    if (res.status === 200) {
      const responseData = await res.json();
      return responseData;
    } else {
      const json = await res.json();
      const response = {
        Message: json.Message,
        Status: res.status,
      };
      throw response;
    }
  } catch (e) {
    throw e;
  }
}

export async function getMasterInfo(token, year, number) {
  try {
    const res = await fetch(`${API_URL}/issue/master/info/${year}/${number}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Token: token,
      },
    });
    if (res.status === 200) {
      const responseData = await res.json();
      return responseData;
    } else {
      const json = await res.json();
      const response = {
        Message: json.Message,
        Status: res.status,
      };
      throw response;
    }
  } catch (e) {
    throw e;
  }
}

export async function getMasterListByClientId(token, userId, clientId) {
  try {
    const res = await fetch(
      `${API_URL}/issue/master/list/by-client/${clientId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
          UserId: userId,
        },
      }
    );
    if (res.status === 200) {
      const responseData = await res.json();
      return responseData;
    } else {
      const json = await res.json();
      const response = {
        Message: json.Message,
        Status: res.status,
      };
      console.log(response);
      throw response;
    }
  } catch (e) {
    throw e;
  }
}

export async function getMasterListByStatusClient(
  token,
  userId,
  status,
  client,
  device
) {
  try {
    const res = await fetch(
      `${API_URL}/issue/master/list?status=${status}&client=${client}&device=${device}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
          UserId: userId,
        },
      }
    );
    if (res.status === 200) {
      const responseData = await res.json();
      return responseData;
    } else {
      const json = await res.json();
      const response = {
        Message: json.Message,
        Status: res.status,
      };
      throw response;
    }
  } catch (e) {
    throw e;
  }
}

export async function cancelMaster(token, year, number) {
  try {
    const res = await fetch(
      `${API_URL}/issue/master/cancel/${year}/${number}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },
      }
    );
    if (res.status === 200) {
      const responseData = await res.json();
      return responseData;
    } else {
      const json = await res.json();
      const response = {
        Message: json.Message,
        Status: res.status,
      };
      throw response;
    }
  } catch (e) {
    throw e;
  }
}

export async function updateMaster(data, year, number) {
  try {
    const res = await fetch(`${API_URL}/issue/master/${year}/${number}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Token: data.Token,
      },
      body: JSON.stringify({
        Address: data.Address,
        DeliveryAddress: data.DeliveryAddress,
        DeliveryMethod: data.DeliveryMethod,
        BillingMethod: data.BillingMethod,
        DeliveryMethodName: data.DeliveryMethodName,
        BillingMethodName: data.BillingMethodName,
        ShippingMethod: data.ShippingMethod,
        ShippingMethodName: data.ShippingMethodName,
        ClientId: data.ClientId,
      }),
    });
    if (res.status === 200) {
      const responseData = await res.json();
      return responseData;
    } else {
      const json = await res.json();
      const response = {
        Message: json.Message,
        Status: res.status,
      };
      throw response;
    }
  } catch (e) {
    throw e;
  }
}

export async function deleteMaster(token, year, number) {
  try {
    const res = await fetch(`${API_URL}/issue/master/${year}/${number}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Token: token,
      },
    });
    if (res.status === 200) {
      const responseData = await res.json();
      return responseData;
    } else {
      const json = await res.json();
      const response = {
        Message: json.Message,
        Status: res.status,
      };
      throw response;
    }
  } catch (e) {
    throw e;
  }
}
