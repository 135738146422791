import React, { useState } from 'react';
import Navbar from '../Navigation/Navbar';
import Menu from '../Navigation/Menu';
import Burger from '../Navigation/Burger';
import { ButtonUp } from './CustomButton';
import styled from 'styled-components';
import { COLORS } from '../../assets/css/_colors';

const NavbarMob = styled.div`
    top: 0;
    position: fixed;
    background-color: ${COLORS.grayLight};
    width: 100%;
    height: 50px;
    z-index: 10;
    opacity: 0.8;
`;

export const DefaultInfo = (props) => {
    const [open, setOpen] = useState(false);

    return (
        <div>
            <Navbar />
            <NavbarMob />
            <Burger open={open} setOpen={setOpen} />
            <Menu open={open} setOpen={setOpen} top={window.pageYOffset} />
            <ButtonUp
                type='button'
                onClick={() =>
                    window.top.scrollTo({ top: 0, behavior: 'smooth' })
                }
            />
        </div>
    );
};
