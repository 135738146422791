import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import MasterInfoEditData from './MasterInfoEditData';
import MasterInfoShowData from './MasterInfoShowData';
import { COLORS } from '../../../assets/css/_colors';

const Container = styled.div`
    width: 100%;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 50%);

    @media (max-width: 767px) {
        grid-template-columns: 100%;
    }
`;

const Element = styled.data`
    width: ${(props) => props.width};
    position: relative;
    padding: 15px 0;
    border-bottom: 1px solid ${COLORS.grayLight};
    color: ${COLORS.green};

    @media (max-width: 767px) {
        width: 100%;
    }
`;

const DataLabel = styled.label`
    color: ${COLORS.grayTextLight};
    margin-left: 10px;
`;

const MasterInfoSaved = ({
    date,
    status,
    user,
    firmName,
    userType,
    phone,
    email,
}) => {
    let { year, number } = useParams();
    useEffect(() => {}, []);

    return (
        <Container>
            <Row style={{ margin: '15px 0' }}>
                <Element width='95%'>
                    <label>Godina:</label>
                    <DataLabel>{year}</DataLabel>
                    <label style={{ marginLeft: '20px' }}>Broj: </label>
                    <DataLabel>{number}</DataLabel>
                </Element>

                <Element width='95%' style={{ justifySelf: 'end' }}>
                    <label>Datum:</label>
                    <DataLabel>{date}</DataLabel>
                </Element>
            </Row>

            {userType === '2' ? (
                <Row style={{ margin: '15px 0' }}>
                    <Element width='95%'>
                        <label>Prijavio:</label>
                        <DataLabel>{user}</DataLabel>
                    </Element>
                </Row>
            ) : (
                <Row style={{ margin: '15px 0' }}>
                    <Element width='95%'>
                        <label>Naziv firme:</label>
                        <DataLabel>{firmName}</DataLabel>
                    </Element>
                    <Element
                        width='95%'
                        style={{
                            justifySelf: 'end',
                        }}
                    >
                        <label>Prijavio:</label>
                        <DataLabel>{user}</DataLabel>
                    </Element>
                </Row>
            )}

            <Row style={{ margin: '15px 0' }}>
                <Element width='95%'>
                    <label>Telefon:</label>
                    {phone ? (
                        <DataLabel>{phone}</DataLabel>
                    ) : (
                        <DataLabel>-</DataLabel>
                    )}
                </Element>
                <Element
                    width='95%'
                    style={{
                        justifySelf: 'end',
                    }}
                >
                    <label>Email:</label>
                    <DataLabel>{email}</DataLabel>
                </Element>
            </Row>

            {status === 0 ? <MasterInfoEditData /> : <MasterInfoShowData />}
        </Container>
    );
};

export default MasterInfoSaved;
