import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Dots } from '../../shared/Dots';
import { COLORS } from '../../../assets/css/_colors';

const Wrapper = styled.div`
    max-width: 800px;
    margin-top: 6px;
    margin-right: ${(props) => (props.side === true ? '3px' : '0')};
    margin-left: ${(props) => (props.side === true ? '0' : '3px')};
    padding: 20px 10px;
    cursor: pointer;
    background-color: white;
    transition: all 0.3s ease;
    overflow-wrap: break-word;
    border-radius: 4px;
    height: auto;
    display: grid;
    grid-template-columns: 15% 75% 10%;

    &:hover {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2),
            0 4px 20px 0px rgba(0, 0, 0, 0.19);
    }

    @media (max-width: 767px) {
        grid-template-columns: 1% auto% 10%;
        margin: 0;
        margin-top: 6px;
    }

    @media (max-width: 550px) {
    }
`;

const LabelValue = styled.p`
    color: black;
    margin: 0;
    margin-top: 5px;
`;

const DetailCard = ({ side, detail, edit }) => {
    let history = useHistory();

    function onCardClick(e) {
        edit
            ? history.push(
                  `/edit/detail/${detail.MasterYear}/${detail.MasterNumber}/${detail.Id}`
              )
            : history.push(
                  `/detail/${detail.MasterYear}/${detail.MasterNumber}/${detail.Id}`
              );
    }

    return (
        <Wrapper side={side} onClick={onCardClick}>
            <label style={{ color: 'black', margin: 'auto 0' }}>
                {detail.Position}.
            </label>
            <div style={{ color: `${COLORS.green}` }}>
                <p style={{ margin: '0' }}>Uređaj: </p>
                <LabelValue>{detail.Device}</LabelValue>
                <p style={{ margin: '0', marginTop: '10px' }}>Status: </p>
                <LabelValue>{detail.StatusName}</LabelValue>
            </div>
            <Dots />
        </Wrapper>
    );
};

export default DetailCard;
