import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { ButtonLoading } from '../shared/CustomButton';
import { handleRegister } from '../../services/authorization/register';
import { sendMail } from '../../services/mail/credentialsMail';
import { LinkForm } from '../shared/LinkForm';
import { SpinnerBtn } from '../shared/Spinner';
import { Bar, Label } from '../shared/CustomInput';
import { COLORS } from '../../assets/css/_colors';
import { useAuth } from '../../context/AuthContext';
import {
  Input,
  Row,
  Element,
  LocalError,
  RowAddress,
} from '../../assets/css/_forms';

import { ButtonBasic } from '../shared/CustomButton';
import { ReactComponent as PersonIcon } from '../../assets/images/employee.svg';
import { ReactComponent as ClientIcon } from '../../assets/images/building.svg';
import Error from '../shared/Error';
import IBError from 'src/services/common/IBError';

const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 60px;
  transition: all 1s ease;

  @media (max-width: 767px) {
  }

  @media (max-width: 480px) {
    margin: 0 10px;
    padding-bottom: 0;
  }
`;

const Subtitle = styled.p`
  color: ${COLORS.grayTextLight};
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 20px;
`;

export const RegisterClientForm = ({ access }) => {
  const authStore = useAuth();
  const history = useHistory();
  const { register, handleSubmit, setError, errors, watch, setValue } =
    useForm();
  const [serverError, setServerError] = useState('');
  const [userType, setUserType] = useState('2');
  const [firstnameCSS, setFirstNameCSS] = useState({ top: '30px' });
  const [lastnameCSS, setLastNameCSS] = useState({ top: '30px' });
  const [oibCSS, setOIBCSS] = useState({ top: '30px' });
  const [firmNameCSS, setFirmNameCSS] = useState({ top: '30px' });
  const [OIB2CSS, setOIB2CSS] = useState({ top: '30px' });
  const [emailCSS, setEmailCSS] = useState({ top: '30px' });
  const [phoneCSS, setPhoneCSS] = useState({ top: '30px' });
  const [addressCSS, setAddressCSS] = useState({ top: '30px' });
  const [deliveryAddressCSS, setDeliveryAddressCSS] = useState({
    top: '30px',
  });
  const [cityCSS, setCityCSS] = useState({ top: '30px' });
  const [zipCodeCSS, setZipCodeCSS] = useState({ top: '30px' });
  const [deliveryCityCSS, setDeliveryCityCSS] = useState({ top: '30px' });
  const [deliveryZipCodeCSS, setDeliveryZipCodeCSS] = useState({
    top: '30px',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (access) {
      setEmailCSS({ top: '0' });
      setValue('email', 'frankotestmail55@gmail.com');
    }
  }, []);

  const onSubmit = async (data) => {
    let err = false;
    if (
      watch('address') !== '' ||
      watch('zipCode') !== '' ||
      watch('city') !== ''
    ) {
      if (watch('address') === '') {
        setError('address', {
          type: 'required',
          message: 'Unesite adresu',
        });
        err = true;
      }
      if (watch('zipCode') === '') {
        setError('zipCode', {
          type: 'required',
          message: 'Unesite poštanski broj',
        });
        err = true;
      }
      if (watch('city') === '') {
        setError('city', {
          type: 'required',
          message: 'Unesite grad',
        });
        err = true;
      }
    }
    if (
      watch('deliveryAddress') !== '' ||
      watch('deliveryZipCode') !== '' ||
      watch('deliveryCity') !== ''
    ) {
      if (watch('deliveryAddress') === '') {
        setError('deliveryAddress', {
          type: 'required',
          message: 'Unesite adresu',
        });
        err = true;
      }
      if (watch('deliveryZipCode') === '') {
        setError('deliveryZipCode', {
          type: 'required',
          message: 'Unesite poštanski broj',
        });
        err = true;
      }
      if (watch('deliveryCity') === '') {
        setError('deliveryCity', {
          type: 'required',
          message: 'Unesite grad',
        });
        err = true;
      }
    }

    if (!err) {
      try {
        setLoading(true);
        const response = await handleRegister(data, userType);
        authStore.login(response.Id, response.Token);
        await sendMail(response.Id);
        history.push('/new/detail');
      } catch (e) {
        if (e.Status === 406) {
          setServerError(e.Message);
          setLoading(false);
        } else {
          // NOTE: more meaningful error message
          throw new IBError('Greška');
        }
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row
        style={{
          marginBottom: '50px',
          height: '50px',
          gridTemplateColumns: 'repeat(2, 50%)',
        }}
      >
        <Element width="95%">
          <ButtonBasic
            style={{
              width: '100%',
              marginRight: '5px',
            }}
            onClick={() => {
              setUserType('2');
              setFirmNameCSS({ top: '30px' });
              setOIB2CSS({ top: '30px' });
            }}
            type="button"
          >
            <PersonIcon fill={`${COLORS.grayDark}`} />
            <span
              style={{
                marginLeft: '10px',
                alignSelf: 'center',
              }}
            >
              fizičko lice
            </span>
          </ButtonBasic>
        </Element>
        <Element width="95%" style={{ justifySelf: 'end' }}>
          <ButtonBasic
            style={{
              width: '100%',
              marginRight: '5px',
            }}
            type="button"
            onClick={() => {
              setUserType('1');
            }}
          >
            <ClientIcon fill={`${COLORS.grayDark}`} />
            <span
              style={{
                marginLeft: '10px',
                alignSelf: 'center',
              }}
            >
              pravno lice
            </span>
          </ButtonBasic>
        </Element>
      </Row>

      {userType === '1' && <Subtitle>Osobni podaci</Subtitle>}

      <Row>
        <Element
          width="95%"
          onBlur={() =>
            watch('firstName') === ''
              ? setFirstNameCSS({
                  top: '30px',
                })
              : setFirstNameCSS({
                  top: '0px',
                })
          }
          onFocus={() => setFirstNameCSS({ top: '0px' })}
        >
          <Input
            ref={register({
              required: {
                value: true,
                message: 'Unesite ime',
              },
              maxLength: {
                value: 25,
                message: 'Ime smije imati najviše 25 znakova',
              },
            })}
            name="firstName"
            type="text"
            onChange={(e) => setServerError('')}
            autoComplete="none"
          />
          <Bar />
          <Label style={firstnameCSS} text="Ime" />
          {errors.firstName && (
            <LocalError>{errors.firstName.message}</LocalError>
          )}
        </Element>
        <Element
          width="95%"
          style={{ justifySelf: 'end' }}
          onBlur={() =>
            watch('lastName') === ''
              ? setLastNameCSS({
                  top: '30px',
                })
              : setLastNameCSS({
                  top: '0px',
                })
          }
          onFocus={() => setLastNameCSS({ top: '0px' })}
        >
          <Input
            name="lastName"
            type="text"
            ref={register({
              required: {
                value: true,
                message: 'Unesite prezime',
              },
              maxLength: {
                value: 50,
                message: 'Prezime smije imati najviše 50 znakova',
              },
            })}
            onChange={(e) => setServerError('')}
            autoComplete="none"
          />
          <Bar />
          <Label style={lastnameCSS} text="Prezime" />
          {errors.lastName && (
            <LocalError>{errors.lastName.message}</LocalError>
          )}
        </Element>
      </Row>

      {userType === '2' && (
        <Row style={{ gridTemplateColumns: '100%' }}>
          <Element
            width="100%"
            onBlur={() =>
              watch('userOIB') === ''
                ? setOIBCSS({
                    top: '30px',
                  })
                : setOIBCSS({
                    top: '0px',
                  })
            }
            onFocus={() => setOIBCSS({ top: '0px' })}
          >
            <Input
              name="userOIB"
              type="text"
              ref={register({
                maxLength: {
                  value: 11,
                  message: 'OIB sadrži 11 znakova',
                },
                minLength: {
                  value: 11,
                  message: 'OIB sadrži 11 znakova',
                },
                pattern: {
                  value: /^[0-9\b]+$/,
                  message: 'OIB mora sadržavati samo brojeve',
                },
              })}
              onChange={(e) => setServerError('')}
              autoComplete="none"
            />
            <Bar />
            <Label style={oibCSS} text="OIB" />
            {errors.userOIB && (
              <LocalError>{errors.userOIB.message}</LocalError>
            )}
          </Element>
        </Row>
      )}

      <Row oneColumn>
        <Element
          width="95%"
          onBlur={() =>
            watch('email') === ''
              ? setEmailCSS({
                  top: '30px',
                })
              : setEmailCSS({
                  top: '0px',
                })
          }
          onFocus={() => setEmailCSS({ top: '0px' })}
        >
          {access ? (
            <Input
              name="email"
              type="text"
              ref={register({
                maxLength: {
                  value: 50,
                  message: 'Email može sadržavati najviše 50 znakova',
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Unesite ispravan email',
                },
              })}
              onChange={(e) => setServerError('')}
              autoComplete="none"
            />
          ) : (
            <Input
              name="email"
              type="text"
              ref={register({
                required: {
                  value: true,
                  message: 'Unesite email',
                },
                maxLength: {
                  value: 50,
                  message: 'Email može sadržavati najviše 50 znakova',
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Unesite ispravan email',
                },
              })}
              onChange={(e) => setServerError('')}
              autoComplete="none"
            />
          )}
          <Bar />
          <Label style={emailCSS} text="Email" />
          {errors.email && <LocalError>{errors.email.message}</LocalError>}
        </Element>
        <Element
          width="95%"
          style={{ justifySelf: 'end' }}
          onBlur={() =>
            watch('phone') === ''
              ? setPhoneCSS({
                  top: '30px',
                })
              : setPhoneCSS({
                  top: '0px',
                })
          }
          onFocus={() => setPhoneCSS({ top: '0px' })}
        >
          <Input
            name="phone"
            type="text"
            ref={register}
            onChange={(e) => setServerError('')}
            autoComplete="none"
          />
          <Bar />
          <Label style={phoneCSS} text="Telefon" />
        </Element>
      </Row>

      {(userType === '1' || userType === '3') && (
        <div>
          <Subtitle>Lokacija</Subtitle>
          <Row>
            <Element
              width="95%"
              onBlur={() =>
                watch('firmName') === ''
                  ? setFirmNameCSS({
                      top: '30px',
                    })
                  : setFirmNameCSS({
                      top: '0px',
                    })
              }
              onFocus={() => setFirmNameCSS({ top: '0px' })}
            >
              <Input
                name="firmName"
                type="text"
                ref={register({
                  maxLength: {
                    value: 100,
                    message: 'naziv firme može sadržavati najviše 100 znakova',
                  },
                  required: {
                    value: true,
                    message: 'Unesite naziv firme',
                  },
                })}
                onChange={(e) => setServerError('')}
                autoComplete="none"
              />
              <Bar />
              <Label style={firmNameCSS} text="Naziv firme" />
              {errors.firmName && (
                <LocalError>{errors.firmName.message}</LocalError>
              )}
            </Element>
            <Element
              width="95%"
              style={{ justifySelf: 'end' }}
              onBlur={() =>
                watch('firmOIB') === ''
                  ? setOIB2CSS({
                      top: '30px',
                    })
                  : setOIB2CSS({
                      top: '0px',
                    })
              }
              onFocus={() => setOIB2CSS({ top: '0px' })}
            >
              <Input
                name="firmOIB"
                type="text"
                ref={register({
                  maxLength: {
                    value: 14,
                    message: 'OIB firme sadrži 14 brojeva',
                  },
                  minLength: {
                    value: 11,
                    message: 'OIB firme sadrži 11 brojeva',
                  },
                  pattern: {
                    value: /^[0-9\b]+$/,
                    message: 'OIB firme mora sadržavati samo brojeve',
                  },
                  required: {
                    value: true,
                    message: 'Unesite OIB firme',
                  },
                })}
                onChange={(e) => setServerError('')}
                autoComplete="none"
              />
              <Bar />
              <Label style={OIB2CSS} text="OIB Firme" />
              {errors.firmOIB && (
                <LocalError>{errors.firmOIB.message}</LocalError>
              )}
            </Element>
          </Row>
        </div>
      )}

      <RowAddress>
        <Element
          width="95%"
          onBlur={() =>
            watch('address') === ''
              ? setAddressCSS({
                  top: '30px',
                })
              : setAddressCSS({
                  top: '0px',
                })
          }
          onFocus={() => setAddressCSS({ top: '0px' })}
        >
          <Input
            name="address"
            type="text"
            ref={register({
              maxLength: {
                value: 100,
                message: 'Adresa može sadržavati najviše 50 znakova',
              },
            })}
            onChange={(e) => setServerError('')}
            autoComplete="none"
          />
          <Bar />
          <Label style={addressCSS} text="Adresa" />
          {errors.address && <LocalError>{errors.address.message}</LocalError>}
        </Element>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '40% auto',
          }}
        >
          <Element
            width="95%"
            onBlur={() =>
              watch('zipCode') === ''
                ? setZipCodeCSS({
                    top: '30px',
                  })
                : setZipCodeCSS({
                    top: '0px',
                  })
            }
            onFocus={() => setZipCodeCSS({ top: '0px' })}
          >
            <Input
              name="zipCode"
              type="text"
              ref={register({
                maxLength: {
                  value: 5,
                  message: 'Poštanski broj sadrži 5 znamenki',
                },
                pattern: {
                  value: /^[0-9\b]+$/,
                  message: 'Poštanski broj mora sadržavati samo brojeve',
                },
              })}
              onChange={(e) => setServerError('')}
              autoComplete="none"
            />
            <Bar />
            <Label style={zipCodeCSS} text="Poštanski broj" />
            {errors.zipCode && (
              <LocalError>{errors.zipCode.message}</LocalError>
            )}
          </Element>
          <Element
            width="95%"
            style={{ justifySelf: 'end' }}
            onBlur={() =>
              watch('city') === ''
                ? setCityCSS({
                    top: '30px',
                  })
                : setCityCSS({
                    top: '0px',
                  })
            }
            onFocus={() => setCityCSS({ top: '0px' })}
          >
            <Input
              name="city"
              type="text"
              ref={register({
                maxLength: {
                  value: 50,
                  message: 'Mjesto može sadržavati najviše 50 znakova',
                },
              })}
              onChange={(e) => setServerError('')}
              autoComplete="none"
            />
            <Bar />
            <Label style={cityCSS} text="Grad" />
            {errors.city && <LocalError>{errors.city.message}</LocalError>}
          </Element>
        </div>
      </RowAddress>

      <RowAddress>
        <Element
          width="95%"
          onBlur={() =>
            watch('deliveryAddress') === ''
              ? setDeliveryAddressCSS({
                  top: '30px',
                })
              : setDeliveryAddressCSS({
                  top: '0px',
                })
          }
          onFocus={() => setDeliveryAddressCSS({ top: '0px' })}
        >
          <Input
            name="deliveryAddress"
            type="text"
            ref={register({
              maxLength: {
                value: 100,
                message: 'Dostavna adresa može sadržavati najviše 50 znakova',
              },
            })}
            onChange={(e) => setServerError('')}
            autoComplete="none"
          />
          <Bar />
          <Label style={deliveryAddressCSS} text="Dostavna adresa" />
          {errors.deliveryAddress && (
            <LocalError>{errors.deliveryAddress.message}</LocalError>
          )}
        </Element>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '40% auto',
          }}
        >
          <Element
            width="95%"
            onBlur={() =>
              watch('deliveryZipCode') === ''
                ? setDeliveryZipCodeCSS({
                    top: '30px',
                  })
                : setDeliveryZipCodeCSS({
                    top: '0px',
                  })
            }
            onFocus={() => setDeliveryZipCodeCSS({ top: '0px' })}
          >
            <Input
              name="deliveryZipCode"
              type="text"
              ref={register({
                maxLength: {
                  value: 5,
                  message: 'Poštanski broj sadrži 5 znamenki',
                },
                minLength: {
                  value: 5,
                  message: 'Poštanski broj sadrži 5 znamenki',
                },
                pattern: {
                  value: /^[0-9\b]+$/,
                  message: 'Poštanski broj mora sadržavati samo brojeve',
                },
              })}
              onChange={(e) => setServerError('')}
              autoComplete="none"
            />
            <Bar />
            <Label style={deliveryZipCodeCSS} text="Poštanski broj" />
            {errors.deliveryZipCode && (
              <LocalError>{errors.deliveryZipCode.message}</LocalError>
            )}
          </Element>
          <Element
            width="95%"
            style={{ justifySelf: 'end' }}
            onBlur={() =>
              watch('deliveryCity') === ''
                ? setDeliveryCityCSS({
                    top: '30px',
                  })
                : setDeliveryCityCSS({
                    top: '0px',
                  })
            }
            onFocus={() => setDeliveryCityCSS({ top: '0px' })}
          >
            <Input
              name="deliveryCity"
              type="text"
              ref={register({
                maxLength: {
                  value: 50,
                  message: 'Mjesto može sadržavati najviše 50 znakova',
                },
              })}
              onChange={(e) => setServerError('')}
              autoComplete="none"
            />
            <Bar />
            <Label style={deliveryCityCSS} text="Grad" />
            {errors.deliveryCity && (
              <LocalError>{errors.deliveryCity.message}</LocalError>
            )}
          </Element>
        </div>
      </RowAddress>

      <ButtonLoading
        style={
          loading
            ? {
                backgroundColor: 'transparent',
                color: `${COLORS.green}`,
                border: `2px solid ${COLORS.green}`,
                paddingTop: '0',
              }
            : null
        }
        type="submit"
      >
        {loading && <SpinnerBtn />}
        Spremi
      </ButtonLoading>

      {!access && (
        <LinkForm
          to={'/login'}
          text={'Imate račun?'}
          linkText={'Klikni ovdje!'}
        />
      )}

      {serverError && <Error msg={serverError} />}

      <Input
        style={{ display: 'none' }}
        name="off"
        type="text"
        autoComplete="off"
      />
    </Form>
  );
};
