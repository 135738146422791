import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import MasterWrapper from './MasterWrapper';
import { getMasterListByStatusClient } from '../../../services/issue/issueMaster';
import { EmptyState } from '../../shared/EmptyState';
import { Spinner } from '../../shared/Spinner';
import useSWR from 'swr';
import IBError from 'src/services/common/IBError';

const MasterList = ({ status, client, device }) => {
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem('token'));
  const userId = JSON.parse(localStorage.getItem('userId'));

  const getMasterList = () => {
    return getMasterListByStatusClient(token, userId, status, client, device);
  };

  const { data, error } = useSWR(
    `/issue/master/list/?status=${status}&client=${client}&device=${device}`,
    getMasterList
  );

  if (error) {
    throw new IBError('Greška');
  }

  if (!data) {
    return (
      <div style={{ marginBottom: '200px' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <div style={{ marginBottom: '200px' }}>
      {data.length <= 0 ? (
        <EmptyState
          msg={'Nije pronaden prijavljeni kvar sa odabranim statusom!'}
          styleImg={{ width: '200px' }}
        />
      ) : (
        data.map((master) => {
          return (
            <MasterWrapper
              key={`${master.Year}${master.Number}`}
              master={master}
              status={status}
              device={device}
            />
          );
        })
      )}
    </div>
  );
};

export default MasterList;
