import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CustomSelect from './CustomSelect';
import { ButtonBasic } from './CustomButton';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import { COLORS } from '../../assets/css/_colors';
import { getDetailStatusList } from '../../services/masterData';
import { getClientList } from '../../services/user/client';
import IBError from 'src/services/common/IBError';
import { Element } from '../../assets/css/_forms';
import { Bar, Input } from '../shared/CustomInput';

const Form = styled.form`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: auto 150px;

  button {
    margin-left: 10px;
    display: inline-flex;
    padding: 6px 12px;

    img {
      vertical-align: middle;
    }
    p {
      margin: 0;
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: 90% auto;
    button {
      margin-left: 10px;
      p {
        display: none;
      }
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: 100%;

    button {
      margin-left: 0;
      margin-top: 10px;

      p {
        display: flex;
      }
    }
  }
`;

const SelectOuter = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33%);

  div {
    margin: 0 2px;
  }

  @media (max-width: 600px) {
    width: 100%;
    grid-template-columns: 100%;

    div {
      margin: 2px 0;
    }
  }
`;

const FilterStatus = ({
  setStatus,
  setClient,
  client,
  status,
  setLoading,
  setDevice,
  device,
}) => {
  const { handleSubmit } = useForm();
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem('token'));
  const userId = JSON.parse(localStorage.getItem('userId'));
  const [optionsStatus, setOptions] = useState([]);
  const [optionsClient, setOptionsClient] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(status);
  const [currentClient, setCurrentClient] = useState(client);
  const [currentDevice, setCurrentDevice] = useState('');

  useEffect(() => {
    fetchStatusList();
    fetchClientList();
  }, []);

  const fetchStatusList = async () => {
    try {
      const data = await getDetailStatusList(token, userId);
      data.forEach((element) => {
        var item = {
          value: element.StatusId,
          label: element.StatusDescription,
        };
        setOptions((prevState) => [...prevState, item]);
      });

      var newItem = {
        value: 100,
        label: 'PRIKAŽI SVE PRIJAVLJENE KVAROVE',
      };
      setOptions((prevState) => [...prevState, newItem]);
    } catch (e) {
      if (e.Status === 401) {
        history.push('/login');
      } else {
        throw new IBError('Greška');
      }
    }
  };

  const fetchClientList = async () => {
    try {
      const data = await getClientList(token, userId);
      data.forEach((element, index) => {
        var item = {
          value: index + 1,
          label: `${element.KomitName} - ${element.Address}, ${element.ZipCode}, ${element.City}`,
          id: element.Id,
        };
        setOptionsClient((prevState) => [...prevState, item]);
      });

      var newItem = {
        value: 100,
        label: 'PRIKAŽI SVE LOKACIJE',
        id: 100,
      };
      setOptionsClient((prevState) => [...prevState, newItem]);
    } catch (e) {
      if (e.Status === 401) {
        history.push('/login');
      } else {
        // NOTE: meaningful error message?
        throw new IBError('Greška');
      }
    }
  };

  function onSelectStatusChange(e) {
    e === null ? setCurrentStatus(100) : setCurrentStatus(e.value);
  }

  function onSelectClientChange(e) {
    e === null ? setCurrentClient(100) : setCurrentClient(e.id);
  }

  const onChangeDevice = (e) => {
    setCurrentDevice(e.target.value);
  };

  const onSubmitForm = async () => {
    setLoading(true);
    setStatus(currentStatus);
    setClient(currentClient);
    setDevice(currentDevice);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitForm)}>
      <SelectOuter>
        <div>
          <CustomSelect
            isSearchable={false}
            isClearable={true}
            placeholder="Pretraga po statusu..."
            name="selectStatus"
            options={optionsStatus}
            onChange={onSelectStatusChange}
          />
        </div>
        <div>
          <CustomSelect
            isSearchable={false}
            isClearable={true}
            name="selectLocation"
            placeholder="Pretraga po lokaciji..."
            options={optionsClient}
            onChange={onSelectClientChange}
          />
        </div>
        <div>
          <Element width="95%" style={{ justifySelf: 'end', padding: '0' }}>
            <Input
              value={currentDevice}
              onChange={onChangeDevice}
              placeholder="Pretraga po serijskom broju"
            />
            <Bar />
          </Element>
        </div>
      </SelectOuter>
      <ButtonBasic type="submit">
        <SearchIcon width="20px" fill={`${COLORS.grayTextLight}`} />
        <p style={{ margin: 'auto', marginLeft: '10px' }}>pretraži</p>
      </ButtonBasic>
    </Form>
  );
};

export default FilterStatus;
