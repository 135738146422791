import React, { PropsWithChildren } from 'react';
import { Wrapper } from 'src/assets/css/_pages';
import Footer from 'src/Components/Footer/Footer';
import { DefaultInfo } from 'src/Components/shared/DefaultInfo';
import Notification from 'src/Components/shared/Notification';
import IBErrorBoundary from '../error/IBErrorBoundary';

interface IPageLayoutOwnProps {
  withNotifications?: boolean;
}
type IPageLayoutProps = PropsWithChildren<IPageLayoutOwnProps>;

const PageLayout: React.FC<IPageLayoutProps> = (props) => {
  return (
    <Wrapper>
      {props.withNotifications && <Notification />}
      <DefaultInfo />
      <IBErrorBoundary>{props.children}</IBErrorBoundary>
      <Footer />
    </Wrapper>
  );
};

export default PageLayout;
