import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FooterForm } from '../shared/FooterForm';
import { ButtonAddClient } from '../shared/CustomButton';
import { Container } from '../../assets/css/_pages';
import 'react-notifications-component/dist/theme.css';
import { ClientForm } from '../Forms/ClientForm';
import { Title } from '../../assets/css/_elements';
import ClientList from '../IssueReport/Client/ClientList';
import mapIcon from '../../assets/images/map.svg';
import PageLayout from '../common/layout/PageLayout';

const ClientPage = (props) => {
  const history = useHistory();
  const [selectedClient, setSelectedClient] = useState();

  return (
    <PageLayout>
      <Container style={{ maxWidth: '800px' }}>
        <ButtonAddClient
          type="button"
          onClick={() => history.push(`/client/new`)}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '50px',
          }}
        >
          <img style={{ width: '100px', marginRight: '10px' }} src={mapIcon} />
          <Title
            style={{
              marginLeft: '10px',
              marginBottom: '50px',
              textAlign: 'center',
            }}
          >
            Lokacije
          </Title>
        </div>

        <ClientList
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
        />

        {selectedClient && (
          <ClientForm edit={true} selectedClient={selectedClient} />
        )}

        <FooterForm
          text={
            'Nakon što se stranica osvježi, Vaši podatci će biti promijenjeni!'
          }
        />
      </Container>
    </PageLayout>
  );
};

export default ClientPage;
