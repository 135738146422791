import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Bar, EditIcon } from '../../shared/CustomInput';
import CustomSelect from '../../shared/CustomSelect';
import { getShippingMethods, getBillingMethods, getDeliveryMethods } from '../../../services/masterData';
import { getClientList } from '../../../services/user/client';
import { COLORS } from '../../../assets/css/_colors';
import { DataContext } from '../../../context/MasterDataContext';
import editIcon from '../../../assets/images/edit.svg';

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  margin: 15px 0;

  .element--custom {
    grid-template-columns: 130px auto;
  }

  @media (max-width: 767px) {
    grid-template-columns: 100%;
  }
`;

const Element = styled.data`
  display: grid;
  width: ${(props) => props.width};
  position: relative;
  padding: 15px 0;
  border-bottom: 1px solid ${COLORS.grayLight};
  color: ${COLORS.green};

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const RowCustom = styled(Row)`
  grid-template-columns: 150px 50%;
  align-items: center;
  padding: 0;
  color: gray;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    grid-template-columns: 150px auto;
  }
`;

const Input = styled.input`
  width: 100%;
  border: 0;
  border-bottom: 1px solid ${COLORS.grayLight};
  display: block;
  height: 40px;
  outline: none;
  font-size: 17px;
  background-color: transparent !important;
  color: ${COLORS.grayTextDark};
  &:focus ~ .bar::before,
  &:focus ~ .bar::after {
    width: 50%;
  }
`;

const MasterInfoEditData = ({}) => {
  const userId = JSON.parse(localStorage.getItem('userId'));
  const token = JSON.parse(localStorage.getItem('token'));
  const { register, setValue, errors, watch } = useForm();
  const [optionsBilling, setOptionsBilling] = useState([]);
  const [optionsDelivery, setOptionsDelivery] = useState([]);
  const [optionsShipping, setOptionsShipping] = useState([]);
  const [optionsClient, setOptionsClient] = useState([]);
  const [currentClientLabel, setCurrentClientLabel] = useState('');
  const [currentClientIndex, setCurrentClientIndex] = useState('');
  const [currentBillingIndex, setCurrentBillingIndex] = useState('');

  const {
    address,
    deliveryAddress,
    setAddress,
    setDeliveryAddress,
    deliveryMethodName,
    setDeliveryMethodName,
    billingMethodName,
    setBillingMethodName,
    currentBillingValue,
    setCurrentBillingValue,
    currentDeliveryValue,
    setCurrentDeliveryValue,
    currentShippingValue,
    setCurrentShippingValue,
    shippingMethodName,
    setShippingMethodName,
    setCurrentClientValue,
    edit,
    currentClientValue,
  } = useContext(DataContext);

  useEffect(() => {
    fetchBillingMethods();
    fetchDeliveryMethods();
    fetchShippingMethods();
    fetchClientList();
  }, []);

  const fetchClientList = async () => {
    try {
      const data = await getClientList(token, userId);
      data.forEach((element, index) => {
        let elementAddress = `${element.Address}, ${element.ZipCode}, ${element.City}`;
        let elementDeliveryAddress = `${element.DeliveryAddress}, ${element.DeliveryZipCode}, ${element.DeliveryCity}`;
        let label = `${element.KomitName} - ${element.Address}, ${element.ZipCode}, ${element.City}`;

        if (!edit && index === 0) {
          setCurrentClientValue(element.Id);
          setCurrentClientLabel(label);
          setCurrentClientIndex(0);
          setValue('addressInput', elementAddress);
          setValue('deliveryAddressInput', elementDeliveryAddress);
          setAddress(elementAddress);
          setDeliveryAddress(elementDeliveryAddress);
        } else if (edit && element.Id === currentClientValue) {
          setCurrentClientLabel(label);
          setCurrentClientIndex(index);
          setValue('addressInput', address);
          setValue('deliveryAddressInput', deliveryAddress);
          setAddress(address);
          setDeliveryAddress(deliveryAddress);
        }

        var item = {
          value: index.toString(),
          label: label,
          id: element.Id,
          firmName: element.KomitName,
          firmId: element.FirmId,
          address: elementAddress,
          deliveryAddress: elementDeliveryAddress,
        };
        setOptionsClient((prevState) => [...prevState, item]);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchShippingMethods = async () => {
    try {
      const data = await getShippingMethods(token, userId);
      data.forEach((element, index) => {
        if (!edit && index === 0) {
          setCurrentShippingValue(element.Id);
          setShippingMethodName(element.Name);
        }

        var item = {
          value: element.Id.toString(),
          label: element.Name.toUpperCase(),
        };
        setOptionsShipping((prevState) => [...prevState, item]);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchBillingMethods = async () => {
    try {
      const data = await getBillingMethods(token, userId);
      data.forEach((element, index) => {
        if (!edit && index === 0) {
          setCurrentBillingValue(element.Id);
          setBillingMethodName(element.Name);
          setCurrentBillingIndex(index.toString());
        } else if (element.Id === currentBillingValue) {
          setBillingMethodName(element.Name);
          setCurrentBillingIndex(index.toString());
        }

        var item = {
          value: index.toString(),
          label: element.Name.toUpperCase(),
          id: element.Id,
        };
        setOptionsBilling((prevState) => [...prevState, item]);

        // if (index === 0) {
        //     if (!edit && index === 0) {
        //         setCurrentBillingValue(element.Id);
        //         setBillingMethodName(element.Name);
        //         setCurrentBillingIndex(index.toString());
        //     } else if (element.Id === currentBillingValue) {
        //         setBillingMethodName(element.Name);
        //         setCurrentBillingIndex(index.toString());
        //     }

        //     var item = {
        //         value: index.toString(),
        //         label: element.Name.toUpperCase(),
        //         id: element.Id,
        //     };
        //     setOptionsBilling((prevState) => [...prevState, item]);
        // } else return;
      });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchDeliveryMethods = async () => {
    try {
      const data = await getDeliveryMethods(token, userId);
      data.forEach((element, index) => {
        if (!edit && index === 0) {
          setCurrentDeliveryValue(element.Id);
          setDeliveryMethodName(element.Name);
        }
        var item = {
          value: element.Id.toString(),
          label: element.Name.toUpperCase(),
        };
        setOptionsDelivery((prevState) => [...prevState, item]);
      });
    } catch (e) {
      console.log(e);
    }
  };

  function onSelectBillingChange(e) {
    if (e === null) {
      setCurrentBillingValue(optionsBilling[0].id);
      setBillingMethodName(optionsBilling[0].label);
      setCurrentBillingIndex(optionsBilling[0].value);
    } else {
      setCurrentBillingValue(e.id);
      setBillingMethodName(e.label);
      setCurrentBillingIndex(e.value.toString());
    }
  }

  function onSelectDeliveryChange(e) {
    if (e === null) {
      setCurrentDeliveryValue(optionsDelivery[0].value);
      setDeliveryMethodName(optionsDelivery[0].label);
    } else {
      setCurrentDeliveryValue(e.value);
      setDeliveryMethodName(e.label);
    }
  }

  function onSelectShippingChange(e) {
    if (e === null) {
      setCurrentShippingValue(optionsShipping[0].value);
      setShippingMethodName(optionsShipping[0].label);
    } else {
      setCurrentShippingValue(e.value);
      setShippingMethodName(e.label);
    }
  }

  function onSelectClientChange(e) {
    console.log(e);
    if (e === null) {
      setCurrentClientValue(optionsClient[0].id);
      setCurrentClientIndex(optionsClient[0].value);
      setCurrentClientLabel(optionsClient[0].label);
      setValue('addressInput', optionsClient[0].address);
      setValue('deliveryAddressInput', optionsClient[0].deliveryAddress);
      setAddress(optionsClient[0].address);
      setDeliveryAddress(optionsClient[0].deliveryAddress);
    } else {
      setCurrentClientValue(e.id);
      setCurrentClientIndex(e.value);
      setCurrentClientLabel(e.label);
      setValue('addressInput', e.address);
      setValue('deliveryAddressInput', e.deliveryAddress);
      setAddress(e.address);
      setDeliveryAddress(e.deliveryAddress);
    }
  }

  return (
    <div>
      <RowCustom>
        <label
          style={{
            color: `${COLORS.green}`,
          }}
        >
          Odaberite lokacije:
        </label>
        <div>
          {!!(optionsClient.length > 0 && currentClientIndex !== '') && (
            <CustomSelect
              isSearchable={false}
              name="selectLocation"
              placeholder="Odaberite lokaciju..."
              options={optionsClient}
              onChange={onSelectClientChange}
              defaultValue={{
                value: currentClientIndex,
                label: currentClientLabel,
              }}
            />
          )}
        </div>
      </RowCustom>

      <Row>
        <Element width="95%" style={{ border: 'none' }}>
          <label>Adresa: </label>
          <EditIcon style={{ marginTop: '20px' }} src={editIcon} />
          <Input name="addressInput" placeholder="Ulica, poštanski broj, grad" type="text" ref={register} autoComplete="off" onChange={(e) => setAddress(watch('addressInput'))} />
          <Bar style={{ top: '74px' }} />
        </Element>
        <Element
          width="95%"
          style={{
            justifySelf: 'end',
            border: 'none',
          }}
        >
          <label>Dostavna adresa: </label>
          <EditIcon style={{ marginTop: '20px' }} src={editIcon} />
          <Input placeholder="Ulica, poštanski broj, grad" ref={register} name="deliveryAddressInput" type="text" autoComplete="off" onChange={(e) => setDeliveryAddress(watch('deliveryAddressInput'))} />
          <Bar style={{ top: '74px' }} />
        </Element>
      </Row>

      <Row>
        <Element
          className="element--custom"
          width="95%"
          style={{
            marginBottom: '10px',
            border: 'none',
            alignItems: 'center',
            padding: '0',
            color: 'gray',
          }}
        >
          <label
            style={{
              color: `${COLORS.green}`,
            }}
          >
            Način dostave:
          </label>
          <div>
            {!!(optionsDelivery.length > 0 && currentDeliveryValue) && (
              <CustomSelect
                isSearchable={false}
                name="selectDeliveryMethod"
                placeholder="Odaberite način dostave..."
                options={optionsDelivery}
                onChange={onSelectDeliveryChange}
                defaultValue={{
                  value: currentDeliveryValue,
                  label: deliveryMethodName,
                }}
              />
            )}
          </div>
        </Element>
        <Element
          className="element--custom"
          width="95%"
          style={{
            justifySelf: 'end',
            marginBottom: '10px',
            border: 'none',
            alignItems: 'center',
            padding: '0',
            color: 'gray',
          }}
        >
          <label
            style={{
              color: `${COLORS.green}`,
            }}
          >
            Način otpreme:
          </label>
          <div>
            {!!(optionsShipping.length > 0 && currentShippingValue) && (
              <CustomSelect
                isSearchable={false}
                name="selectShippingMethod"
                options={optionsShipping}
                onChange={onSelectShippingChange}
                defaultValue={{
                  value: currentShippingValue,
                  label: shippingMethodName,
                }}
              />
            )}
          </div>
        </Element>
      </Row>

      <Row>
        <Element
          className="element--custom"
          width="95%"
          style={{
            marginBottom: '10px',
            border: 'none',
            alignItems: 'center',
            padding: '0',
            color: 'gray',
          }}
        >
          <label
            style={{
              color: `${COLORS.green}`,
            }}
          >
            Način plaćanja:
          </label>
          <div>
            {!!(optionsBilling.length > 0 && currentBillingValue) && (
              <CustomSelect
                isSearchable={false}
                isClearable={false}
                name="selectBillingMethod"
                placeholder="Odaberite način plačanja..."
                options={optionsBilling}
                onChange={onSelectBillingChange}
                defaultValue={{
                  value: currentBillingValue,
                  label: billingMethodName,
                }}
              />
            )}
          </div>
        </Element>
      </Row>
    </div>
  );
};

export default MasterInfoEditData;
