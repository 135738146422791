import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import DetailForm from '../Forms/DetailForm';
import MasterInfoSaved from '../IssueReport/Master/MasterInfoSaved';
import Warning from '../shared/Warning';
import { Spinner } from '../shared/Spinner';
import { getUserInfo } from '../../services/user/info';
import { getMasterInfo } from '../../services/issue/issueMaster';
import { DataContext } from '../../context/MasterDataContext';
import { Container } from '../../assets/css/_pages';
import { Title } from '../../assets/css/_elements';
import arrow_next from '../../assets/images/arrow_next.svg';
import PageLayout from '../common/layout/PageLayout';

const EditPage = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  const userId = JSON.parse(localStorage.getItem('userId'));
  let { year, number } = useParams();
  const [userType, setUserType] = useState('');
  const [status, setStatus] = useState('');
  const [date, setDate] = useState('');
  const [user, setUser] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [firmName, setFirmName] = useState('');
  const [loading, setLoading] = useState(true);
  const {
    setAddress,
    setDeliveryAddress,
    setDeliveryMethodName,
    setBillingMethodName,
    setShippingMethodName,
    setCurrentBillingValue,
    setCurrentDeliveryValue,
    setCurrentShippingValue,
    setEdit,
    setCurrentClientValue,
  } = useContext(DataContext);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setEdit(true);
      const data = await getMasterInfo(token, year, number);
      setStatus(data.Status);
      setDate(data.Date);
      setDeliveryAddress(data.DeliveryAddress);
      setAddress(data.Address);
      setDeliveryMethodName(data.DeliveryMethodName);
      setBillingMethodName(data.BillingMethodName);
      setShippingMethodName(data.ShippingMethodName);
      setCurrentBillingValue(data.BillingMethod);
      setCurrentDeliveryValue(data.DeliveryMethod.toString());
      setCurrentShippingValue(data.ShippingMethod.toString());
      setCurrentClientValue(data.UserId);

      const userData = await getUserInfo(token, userId);
      setFirmName(userData.FirmName);
      setPhone(userData.Phone);
      setEmail(userData.Email);
      setUser(userData.FirstName + ' ' + userData.LastName);
      setUserType(data.UserType);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageLayout>
      <Container>
        {loading ? (
          <Spinner style={{ marginTop: '100px' }} />
        ) : (
          <div>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              {/* <img
                                style={{ marginRight: '20px' }}
                                src={require('../../assets/images/arrow_next.svg')}
                            /> */}
              <img style={{ marginRight: '20px' }} src={arrow_next} />
              <Title>Prijava kvara</Title>
            </div>
            <MasterInfoSaved
              date={date}
              status={status}
              user={user}
              firmName={firmName}
              userType={userType}
              phone={phone}
              email={email}
            />

            <Warning
              msg={
                'Ako promijenite neke od podataka vezanih uz prijavu kvara, oni će se promijeniti za sve stavke tog kvara!'
              }
            />
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <img style={{ marginRight: '20px' }} src={arrow_next} />
              <Title>Stavka prijavljenog kvara</Title>
            </div>

            <DetailForm edit={true} />
          </div>
        )}
      </Container>
    </PageLayout>
  );
};

export default EditPage;
