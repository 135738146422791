import React, { useEffect } from 'react';
import Select from 'react-select';

const CustomSelect = ({
    options,
    onChange,
    name,
    placeholder,
    value,
    disabled,
    loading,
    defaultValue,
    isClearable,
    isSearchable,
}) => {
    useEffect(() => {}, []);
    return (
        <Select
            isSearchable={isSearchable}
            isDisabled={disabled}
            isLoading={loading}
            placeholder={placeholder}
            name={name}
            options={options}
            isClearable={isClearable}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
        />
    );
};

export default CustomSelect;
