import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../context/AuthContext';
import { LoginForm } from '../Forms/LoginForm';
import { FooterForm } from '../shared/FooterForm';
import { COLORS } from '../../assets/css/_colors';
import { Notification } from '../shared/Notification';
import { Title } from '../../assets/css/_elements';

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    background: ${COLORS.grayLightBackground};
    position: absolute;
    z-index: 1;
    padding: 10px;
`;

const Container = styled.div`
    max-width: 800px;
    margin: 0 auto;
    transition: all 1s ease;
    padding: 0 40px;

    @media (max-width: 480px) {
        padding: 0;
    }
`;

const Login = (props) => {
    const authStore = useAuth();

    useEffect(() => {
        authStore.logout();
    }, []);

    return (
        <Wrapper>
            {/* <Notification /> */}
            <Container>
                <Title style={{ textAlign: 'center' }}>PRIJAVA</Title>
                <LoginForm />
                <FooterForm
                    link
                    text={'Zaboravili ste korisničko ime i/ili lozinku?'}
                />
            </Container>
        </Wrapper>
    );
};

export default Login;
