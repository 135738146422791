import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../assets/css/_colors';

const Container = styled.div`
    width: 100%;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 50%);
    margin: 15px 0;

    .element--description {
    }

    @media (max-width: 767px) {
        grid-template-columns: 100%;

        .element--description {
            border-bottom: none;
        }
    }
`;

const Element = styled.data`
    width: ${(props) => props.width};
    position: relative;
    padding: 15px 0;
    border-bottom: 1px solid ${COLORS.grayLight};
    color: ${COLORS.green};

    @media (max-width: 767px) {
        width: 100%;
    }
`;

const DataLabel = styled.label`
    color: ${COLORS.grayTextLight};
    margin-left: 10px;
`;

const CustomLabel = styled.span`
    color: white;
    background-color: ${COLORS.grayTextLight};
    padding: 7px 15px;
    margin: 0 5px;
    border-radius: 3px;
    opacity: 0.8;
`;

const Textarea = styled.textarea`
    width: 100%;
    border: 0;
    display: block;
    outline: none;
    background-color: transparent !important;
    color: ${COLORS.grayTextDark};
    resize: none;
    font-size: 17px;

    &:focus ~ .bar::before,
    &:focus ~ .bar::after {
        width: 50%;
    }
`;

const DetailInfoSaved = ({
    position,
    statusName,
    status,
    repairerName,
    workOrder,
    device,
    serialNumber,
    type,
    description,
    response,
    id,
}) => {
    var arrayOfTypes = [];

    useEffect(() => {}, []);

    return (
        <Container>
            <Row>
                <Element width='95%'>
                    <label>Redni broj: </label>
                    <DataLabel>{position}</DataLabel>
                </Element>
                <Element width='95%' style={{ justifySelf: 'end' }}>
                    <label>Status: </label>
                    <DataLabel>{statusName}</DataLabel>
                </Element>
            </Row>

            {status === 2 && (
                <Row>
                    <Element width='95%'>
                        <label>Naziv servisera:</label>
                        <DataLabel>{repairerName}</DataLabel>
                    </Element>
                    <Element width='95%' style={{ justifySelf: 'end' }}>
                        <label>Radni nalog:</label>
                        <DataLabel>{workOrder}</DataLabel>
                    </Element>
                </Row>
            )}

            <Row>
                <Element width='95%'>
                    <label>Uređaj:</label>
                    <DataLabel>{device}</DataLabel>
                </Element>
                <Element width='95%' style={{ justifySelf: 'end' }}>
                    <label>Serijski broj:</label>
                    {serialNumber ? (
                        <DataLabel>{serialNumber}</DataLabel>
                    ) : (
                        <DataLabel>-</DataLabel>
                    )}
                </Element>
            </Row>

            {type.length > 0 && (
                <Row style={{ gridTemplateColumns: '100%' }}>
                    <Element
                        width='100%'
                        style={{ border: 'none', margin: '20px 0' }}
                    >
                        <label style={{ marginRight: '10px' }}>
                            Vrsta prijave:
                        </label>
                        {type.forEach((element) =>
                            arrayOfTypes.push(
                                <CustomLabel key={element}>
                                    {element}
                                </CustomLabel>
                            )
                        )}
                        {arrayOfTypes}
                    </Element>
                </Row>
            )}

            <Row style={{ margin: '40px 0' }}>
                <Element
                    className='element--description'
                    width='95%'
                    style={{
                        paddingTop: '0',
                        marginBottom: '20px',
                    }}
                >
                    <h3
                        style={{
                            marginTop: '0',
                            color: `${COLORS.grayTextLight}`,
                        }}
                    >
                        Opis prijave
                    </h3>
                    <Textarea
                        disabled
                        rows='5'
                        name='description'
                        type='text'
                        value={description}
                    />
                </Element>
                <Element
                    className='element--description'
                    width='95%'
                    style={{
                        justifySelf: 'end',
                        paddingTop: '0',
                        marginBottom: '20px',
                    }}
                >
                    <h3
                        style={{
                            marginTop: '0',
                            color: `${COLORS.grayTextLight}`,
                        }}
                    >
                        Odgovor servisera
                    </h3>
                    <Textarea
                        disabled
                        rows='5'
                        name='response'
                        type='text'
                        value={response}
                    />
                </Element>
            </Row>
        </Container>
    );
};

export default DetailInfoSaved;
