import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../assets/css/_colors';

const Container = styled.div`
    border-top: 1px solid ${COLORS.grayDark};
    max-width: 650px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 70% 30%;
    padding-bottom: 10px;

    p {
        margin: 0;
        padding: 10px 0;
    }

    p:first-of-type {
        margin-left: 10px;
    }
    p:last-of-type {
        margin-right: 10px;
        text-align: right;
    }

    @media (max-width: 480px) {
        max-width: 100%;
        grid-template-columns: 100%;
        text-align: center;
        margin: 0 20px;
        padding: 0;

        p:first-of-type {
            margin-left: 0;
        }
        p:last-of-type {
            margin-right: 0;
            text-align: center;
        }
    }
`;

const CopyRight = (props) => {
    return (
        <Container>
            <p>© 2020 Infodata / All rights reserved.</p>
            <p>Produced by © Infobit</p>
        </Container>
    );
};

export default CopyRight;
