import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { sendRecoverCredentialsMail } from '../../services/mail/credentialsMail';
import { ButtonLoading } from '../shared/CustomButton';
import { LinkForm } from '../shared/LinkForm';
import { Bar } from '../shared/CustomInput';
import Error from '../shared/Error';
import { SpinnerBtn } from '../shared/Spinner';
import { LocalError } from '../../assets/css/_forms';
import { COLORS } from '../../assets/css/_colors';
import {
  LoginForm as Form,
  LoginRow as Row,
  Element,
  Input,
  LabelContainer,
} from '../../assets/css/_forms';
import loginUsernameIcon from '../../assets/images/login-user.svg';
import IBError from 'src/services/common/IBError';

export const RecoverCredentialsForm = (props) => {
  const { register, handleSubmit, errors, watch, setValue } = useForm();
  const history = useHistory();
  const [usernameCSS, setUsernameCSS] = useState({ top: '30px' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      setError('');
      await sendRecoverCredentialsMail(data.username);
      localStorage.setItem(
        'actionMessage',
        'Pristupni podatci su Vam poslani na email!'
      );
      history.push('/');
    } catch (e) {
      console.log(e);
      if (e.Status === 401) {
        setError(e.Message);
        setLoading(false);
        setValue('username', null);
      } else {
        // NOTE: more meaningful error message ?
        throw new IBError('Greška');
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Element
          width="100%"
          onFocus={() => setUsernameCSS({ top: '0px' })}
          onBlur={() =>
            watch('username') === ''
              ? setUsernameCSS({ top: '30px' })
              : setUsernameCSS({ top: '0px' })
          }
        >
          <Input
            autoComplete="off"
            name="username"
            type="text"
            ref={register({
              required: {
                value: true,
                message: 'Unesite korisničko ime',
              },
              maxLength: {
                value: 25,
                message: 'Korisničko ime ne smije imati najviše 25 znakova',
              },
            })}
            onChange={(e) => setError('')}
          />
          <Bar />
          <LabelContainer style={usernameCSS}>
            <img
              style={{
                transition: 'all 0.2s ease',
                width: '15px',
                marginRight: '10px',
              }}
              src={loginUsernameIcon}
              alt="login"
            />
            <span>Korisničko ime</span>
          </LabelContainer>
          {errors.username && (
            <LocalError>{errors.username.message}</LocalError>
          )}
        </Element>
      </Row>

      <ButtonLoading
        style={
          loading
            ? {
                backgroundColor: 'transparent',
                color: `${COLORS.green}`,
                border: `2px solid ${COLORS.green}`,
                paddingTop: '0',
              }
            : null
        }
        type="submit"
      >
        {loading && <SpinnerBtn />}
        Pošalji
      </ButtonLoading>

      <LinkForm
        to={'/login'}
        text={'Imate račun?'}
        linkText={'Prijavite se!'}
      />

      {error && <Error msg={error} />}
    </Form>
  );
};
