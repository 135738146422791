import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getCurrentDate } from '../../../utils/getCurrentDate';
import { ButtonBasic } from '../../shared/CustomButton';
import { DialogWarning } from '../../shared/Dialog';
import { cancelMaster } from '../../../services/issue/issueMaster';
import { getDetailListByStatus } from '../../../services/issue/issueDetail';
import useSWR from 'swr';
import deleteIcon from '../../../assets/images/delete.svg';
import smallPlusIcon from '../../../assets/images/plusSmall.svg';
import IBError from 'src/services/common/IBError';

const Buttons = styled.div`
  display: flex;
  padding: 20px 0;

  @media (max-width: 767px) {
    padding: 20px 0;
  }

  @media (max-width: 480px) {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
`;

const MasterWrapperButtons = ({ master, status, year, number, device }) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const getMasterList = () => {
    return getDetailListByStatus(token, year, number, status);
  };

  const { data, error } = useSWR(
    `/issue/detail/list/${status}/${year}/${number}/${device ? device : '0'}`,
    getMasterList
  );

  const onMasterDeleteClick = async () => {
    try {
      await cancelMaster(token, master.Year, master.Number);
      window.location.reload(false);
    } catch (e) {
      if (e.Status === 401) {
        history.push('/login');
      } else {
        throw new IBError('Greška');
      }
    }
  };

  if (error) {
    throw new IBError(error.message);
  }

  if (!data) {
    return <div></div>;
  }

  return (
    <div>
      {((master.Status === 0 && data.length === 0) ||
        master.Date.substring(0, 11) === getCurrentDate()) && (
        <Buttons>
          {master.Status === 0 && data.length === 0 && (
            <ButtonBasic
              style={{
                marginRight: '5px',
              }}
              type="button"
              onClick={(e) => setOpen(true)}
            >
              <img src={deleteIcon} />
              <span
                style={{
                  marginLeft: '10px',
                  alignSelf: 'center',
                }}
              >
                Obriši
              </span>
            </ButtonBasic>
          )}
          {master.Date.substring(0, 11) === getCurrentDate() && (
            <ButtonBasic
              style={{
                marginLeft: '5px',
              }}
              type="button"
              onClick={(e) =>
                history.push(`/add/detail/${master.Year}/${master.Number}`)
              }
            >
              <img src={smallPlusIcon} />
              <span
                style={{
                  marginLeft: '10px',
                  alignSelf: 'center',
                }}
              >
                Dodaj
              </span>
            </ButtonBasic>
          )}
        </Buttons>
      )}
      <DialogWarning
        open={open}
        onClose={() => setOpen(false)}
        title={'Upozorenje!'}
        message={'Jeste sigurni da želite obrisati prijavljeni kvar?'}
        onClickCancel={() => setOpen(false)}
        onClickOk={onMasterDeleteClick}
        cancelText={'odustani'}
        okText={'obriši'}
      />
    </div>
  );
};

export default MasterWrapperButtons;
