import React, { useState } from 'react';
import styled from 'styled-components';
import successIcon from '../../assets/images/checked.svg';

const Container = styled.div`
    background-color: #28a745;
    border-left: 8px solid #1f8838;
    width: 100%;
    color: white;
    border-radius: 3px;
    display: inline-flex;
    padding: 10px;
    align-items: center;
`;

export const NotificationCard = (props) => {
    const actionMessage = localStorage.getItem('actionMessage');
    return (
        <Container>
            <img style={{ marginRight: '10px' }} src={successIcon} />
            <p style={{ margin: '0' }}>{actionMessage}</p>
        </Container>
    );
};
