import React from 'react';
import styled from 'styled-components';
import { FooterForm } from '../shared/FooterForm';
import { Container } from '../../assets/css/_pages';
import 'react-notifications-component/dist/theme.css';
import { ClientForm } from '../Forms/ClientForm';
import { Title } from '../../assets/css/_elements';
import mapIcon from '../../assets/images/map.svg';
import PageLayout from '../common/layout/PageLayout';

const Img = styled.img`
  width: 150px;
  height: 180px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  transition: all 0.4s ease;

  @media (max-width: 767px) {
    width: 120px;
    height: 150px;
  }
`;

const ClientPage = ({}) => {
  return (
    <PageLayout>
      <Container style={{ maxWidth: '700px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '50px',
          }}
        >
          <img style={{ width: '100px', marginRight: '10px' }} src={mapIcon} />
          <Title
            style={{
              marginLeft: '10px',
              marginBottom: '50px',
              textAlign: 'center',
            }}
          >
            Dodaj lokaciju
          </Title>
        </div>

        <ClientForm edit={false} />

        <FooterForm
          text={
            'Nakon što se stranica osvježi, Vaši podatci će biti promijenjeni!'
          }
        />
      </Container>
    </PageLayout>
  );
};

export default ClientPage;
