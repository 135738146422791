export const COLORS = {
    // RED
    red: 'red',
    redErrorText: '#721c24',
    redErrorBackground: '#f8d7da',
    redErrorBorder: '#f5c6cb',

    // GRAY
    grayLightBackground: '#f5f5f5',
    grayDarkBackground: '#333333',
    grayDark: '#5f5f5f',
    grayLight: '#c2c1c1',

    grayTextDark: '#333',
    grayTextLight: '#777777',
    grayBorderLight: '#e9e9e9',

    // GREEN
    green: 'green',
    greenLight: 'rgb(75, 158, 75)',
    greenLightOpacity: 'rgb(132, 184, 132)',
    // rgb(160, 204, 160)
};
