import React from 'react';
import styled from 'styled-components';
import { RegisterForm } from '../Forms/RegisterForm';
import { RegisterClientForm } from '../Forms/RegisterClientForm';
import { FooterForm } from '../shared/FooterForm';
import { COLORS } from '../../assets/css/_colors';
import { Title } from '../../assets/css/_elements';

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    background: ${COLORS.grayLightBackground};
    position: absolute;
    z-index: 1;
    padding: 10px;
`;

const Container = styled.div`
    max-width: 800px;
    margin: 0 auto;
    transition: all 1s ease;
    padding: 0 40px;

    @media (max-width: 480px) {
        padding: 0;
    }
`;

const RegisterCredentials = (props) => {
    return (
        <Wrapper>
            <Container>
                <Title style={{ textAlign: 'center' }}>REGISTRACIJA</Title>
                <RegisterClientForm />
                <FooterForm
                    text={
                        'Korisničko ime i lozinka će Vam biti poslane na unesenu email adresu!'
                    }
                />
            </Container>
        </Wrapper>
    );
};

export default RegisterCredentials;
