import React from 'react';
import styled from 'styled-components';
import locationIcon from '../../assets/images/location.svg';

const Container = styled.div`
  max-width: 220px;
  margin: 0 auto;
  padding-bottom: 30px;
  padding-top: 20px;

  p {
    margin: 2px 0;
  }
`;

const Info = (props) => {
  return (
    <Container>
      <h3 style={{ textAlign: 'center' }}>INFODATA</h3>
      <div style={{ display: 'inline-flex', marginBottom: '10px' }}>
        <img src={locationIcon} />
        <p style={{ marginLeft: '10px' }}>
          CMP Savica Šanci 111,<br></br> 10000 Zagreb
        </p>
      </div>

      <p>
        Telefon:
        <label
          style={{
            color: 'white',
            marginLeft: '5px',
            fontSize: '18px',
          }}
        >
          (01) 2409-140
        </label>
      </p>
      <p>
        Fax:
        <label
          style={{
            color: 'white',
            marginLeft: '5px',
            fontSize: '18px',
          }}
        >
          (01) 2409-151
        </label>
      </p>
      <p>
        Email:
        <label
          style={{
            color: 'white',
            marginLeft: '5px',
            fontSize: '18px',
          }}
        >
          infodata@infodata.hr
        </label>
      </p>
    </Container>
  );
};

export default Info;
