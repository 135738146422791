import React from 'react';
import styled from 'styled-components';
import { ContactForm } from '../Forms/ContactForm';
import { Container } from '../../assets/css/_pages';
import { Title } from '../../assets/css/_elements';
import sendMailIcon from '../../assets/images/sendMail.svg';
import PageLayout from '../common/layout/PageLayout';

const ContainerContact = styled(Container)`
  width: 700px;
  @media (max-width: 767px) {
    width: 100%;
  }

  @media (max-width: 480px) {
  }
`;

export const ContactPage = () => {
  return (
    <PageLayout>
      <ContainerContact>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '50px',
          }}
        >
          <img
            style={{ width: '100px', marginRight: '10px' }}
            src={sendMailIcon}
          />
          <Title
            style={{
              marginLeft: '10px',
              marginBottom: '50px',
              textAlign: 'center',
            }}
          >
            Pošaljite nam mail
          </Title>
        </div>
        <ContactForm />
      </ContainerContact>
    </PageLayout>
  );
};
