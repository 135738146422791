/** Error base class used for all other custom errors. */
export default class IBError {
  name: string;
  message: string;
  stack?: string;

  constructor(message: string, name?: string) {
    this.message = message;
    this.name = name || this.constructor.name || 'IBError';
    this.stack = new Error().stack;
  }
}
