import React, { PropsWithChildren, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'src/context/AuthContext';

// -- Props
// -----

interface IAuthenticationRouteGuardOwnProps {}
type IAuthenticationRouteGuardProps = PropsWithChildren<IAuthenticationRouteGuardOwnProps>;

// -- Component
// -----

/** HOC for wrapping routes with authentication guard */
const AuthenticationRouteGuard: React.FC<IAuthenticationRouteGuardProps> = (props) => {
  const authStore = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!authStore.token) {
      history.push('/login');
    }
  }, []);

  return <React.Fragment>{authStore.token ? props.children : null}</React.Fragment>;
};

// -- HOCs and exports
// -----

export default AuthenticationRouteGuard;
