import React from 'react';
import ReactDOM from 'react-dom';
import appRouter from './Components/common/route/appRouter';
import App from './Components/App';

const root = document.getElementById('root');

const app = (
  <React.Fragment>
    <App>{appRouter}</App>
  </React.Fragment>
);

ReactDOM.render(app, root);
