import React, { PropsWithChildren } from 'react';
import LocalizeService from 'src/services/locale/LocaleService';
import MESSAGES from './../../services/locale/messages';

// -- Prop types
// -----

export interface ILocaleProviderOwnProps {}

type ILocaleProviderProps = PropsWithChildren<ILocaleProviderOwnProps>;

// -- Component
// -----

// NOTE: placeholder just in case we ever need multilocalization
/** Central point to set/modify app localization */
const LocaleProvider: React.FC<ILocaleProviderProps> = (props) => {
  LocalizeService.initLocalization('hr', MESSAGES);

  return <React.Fragment>{props.children}</React.Fragment>;
};

// -- HOC and export
// -----

export default LocaleProvider;
