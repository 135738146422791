import React from 'react';
import styled from 'styled-components';
import DetailCard from './DetailCard';
import { useHistory } from 'react-router-dom';
import { getDetailListByStatus } from '../../../services/issue/issueDetail';
import { EmptyState } from '../../shared/EmptyState';
import useSWR from 'swr';
import { Spinner } from '../../shared/Spinner';
import IBError from 'src/services/common/IBError';

const DetailView = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);

  @media (max-width: 780px) {
    grid-template-columns: 100%;
  }
`;

const DetailWrapper = ({ status, year, number, device }) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const history = useHistory();

  const getMasterList = () => {
    return getDetailListByStatus(token, year, number, status, device);
  };

  const { data, error } = useSWR(
    `/issue/detail/list/${status}/${year}/${number}/${device ? device : '0'}`,
    getMasterList
  );

  if (error) {
    throw new IBError(error.message);
  }

  if (!data) {
    return (
      <div style={{ marginBottom: '200px' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {data.length === 0 ? (
        <EmptyState msg={'Nemate prijavljenih stavaka kvara!'} />
      ) : (
        <DetailView>
          {data.map((detail, index) => {
            if (detail.Status === 0)
              return (
                <DetailCard
                  side={index % 2 === 0}
                  key={index}
                  detail={detail}
                  edit
                />
              );
            else
              return (
                <DetailCard
                  side={index % 2 === 0}
                  key={index}
                  detail={detail}
                />
              );
          })}
        </DetailView>
      )}
    </>
  );
};

export default DetailWrapper;
