import React from 'react';
import DetailSavedCard from '../IssueReport/Detail/DetailSavedCard';
import PageLayout from '../common/layout/PageLayout';

const SavedPage = (props) => {
  return (
    <PageLayout>
      <DetailSavedCard />
    </PageLayout>
  );
};

export default SavedPage;
