import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import CustomSelect from '../../shared/CustomSelect';
import { COLORS } from '../../../assets/css/_colors';
import { DataContext } from '../../../context/MasterDataContext';

const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 50%);
    margin: 15px 0;

    .element--custom {
        display: grid;
        grid-template-columns: 130px auto;
    }

    @media (max-width: 767px) {
        grid-template-columns: 100%;
    }
`;

const Element = styled.data`
    width: ${(props) => props.width};
    position: relative;
    padding: 15px 0;
    border-bottom: 1px solid ${COLORS.grayLight};
    color: ${COLORS.green};

    @media (max-width: 767px) {
        width: 100%;
    }
`;

const ElementCustom = styled(Element)`
    margin-bottom: 10px;
    border: none;
    align-items: center;
    padding: 0;

    @media (max-width: 480px) {
        display: grid;
        grid-template-columns: 100% !important;

        .select {
            margin-top: 10px;
        }
    }
`;

const DataLabel = styled.label`
    color: ${COLORS.grayTextLight};
    margin-left: 10px;
`;

const RowCustom = styled(Row)`
    grid-template-columns: 150px 50%;
    align-items: center;
    padding: 0;
    color: gray;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;

    @media (max-width: 767px) {
        grid-template-columns: 150px auto;
    }
`;

const MasterInfoShowData = ({}) => {
    const {
        deliveryAddress,
        address,
        currentDeliveryValue,
        deliveryMethodName,
        currentBillingValue,
        billingMethodName,
        currentShippingValue,
        shippingMethodName,
        currentClientValue,
        currentClientName,
    } = useContext(DataContext);

    useEffect(() => {}, []);

    return (
        <div>
            <RowCustom>
                <label style={{ color: `${COLORS.green}` }}>
                    Odaberite lokacije:
                </label>
                <div className='select'>
                    <CustomSelect
                        name='selectLocation'
                        disabled
                        defaultValue={{
                            value: currentClientValue,
                            label: currentClientName,
                        }}
                    />
                </div>
            </RowCustom>
            <Row style={{ margin: '15px 0' }}>
                <Element width='95%'>
                    <label>Adresa:</label>
                    {address ? (
                        <DataLabel>{address}</DataLabel>
                    ) : (
                        <DataLabel>-</DataLabel>
                    )}
                </Element>
                <Element
                    width='95%'
                    style={{
                        justifySelf: 'end',
                    }}
                >
                    <label>Dostavna adresa:</label>
                    {deliveryAddress ? (
                        <DataLabel>{deliveryAddress}</DataLabel>
                    ) : (
                        <DataLabel>-</DataLabel>
                    )}
                </Element>
            </Row>

            <Row style={{ margin: '15px 0' }}>
                <ElementCustom width='95%' className='element--custom'>
                    <label>Način dostave:</label>
                    <div className='select'>
                        <CustomSelect
                            name='selectDeliveryMethod'
                            disabled
                            defaultValue={{
                                value: currentDeliveryValue,
                                label: deliveryMethodName,
                            }}
                        />
                    </div>
                </ElementCustom>

                <ElementCustom
                    width='95%'
                    className='element--custom'
                    style={{
                        justifySelf: 'end',
                    }}
                >
                    <label>Način otpreme:</label>
                    <div className='select'>
                        <CustomSelect
                            name='selectShippingMethod'
                            disabled
                            defaultValue={{
                                value: currentShippingValue,
                                label: shippingMethodName,
                            }}
                        />
                    </div>
                </ElementCustom>
            </Row>
            <Row>
                <ElementCustom width='95%' className='element--custom'>
                    <label>Način plačanja:</label>
                    <div className='select'>
                        <CustomSelect
                            name='selectBillingMethod'
                            disabled
                            defaultValue={{
                                value: currentBillingValue,
                                label: billingMethodName,
                            }}
                        />
                    </div>
                </ElementCustom>
            </Row>
        </div>
    );
};

export default MasterInfoShowData;
