import React from 'react';
import styled from 'styled-components';
import MasterCard from './MasterCard';
import DetailWrapper from '../Detail/DetailWrapper';
import MasterWrapperButtons from './MasterWrapperButtons';

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 50px auto;
  margin-bottom: 100px;
  border-radius: 5px;
  background-color: transparent;

  label {
    font-size: 17px;
  }

  @media (max-width: 480px) {
    label {
      font-size: 15px;
    }
  }
`;

const MasterWrapper = ({ master, status, device }) => {
  return (
    <Wrapper>
      <MasterCard master={master} />

      <DetailWrapper
        status={status}
        year={master.Year}
        number={master.Number}
        device={device}
      />

      <MasterWrapperButtons
        master={master}
        status={status}
        year={master.Year}
        number={master.Number}
        device={device}
      />
    </Wrapper>
  );
};

export default MasterWrapper;
