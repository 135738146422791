import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getDetailInfo } from '../../../services/issue/issueDetail';
import { getMasterInfo } from '../../../services/issue/issueMaster';
import { Spinner } from '../../shared/Spinner';
import MasterInfoSaved from '../Master/MasterInfoSaved';
import { getUserInfo } from '../../../services/user/info';
import DetailInfoSaved from './DetailInfoSaved';
import { DataContext } from '../../../context/MasterDataContext';
import { Container } from '../../../assets/css/_pages';
import { Title } from '../../../assets/css/_elements';
import nextIcon from '../../../assets/images/arrow_next.svg';

const DetailSavedCard = () => {
  const userId = JSON.parse(localStorage.getItem('userId'));
  const token = JSON.parse(localStorage.getItem('token'));
  const { year, number, id } = useParams();
  const [loading, setLoading] = useState(true);

  // Detail
  const [repairerName, setRepairerName] = useState();
  const [workOrder, setWorkOrder] = useState();
  const [description, setDescription] = useState();
  const [response, setResponse] = useState();
  const [position, setPosition] = useState();
  const [status, setStatus] = useState();
  const [device, setDevice] = useState();
  const [date, setDate] = useState();
  const [serialNumber, setSerialNumber] = useState();
  const [type, setType] = useState([]);
  const [statusName, setStatusName] = useState();

  // Master
  const [masterStatus, setMasterStatus] = useState('');
  const [masterStatusName, setMasterStatusName] = useState('');

  // User
  const [user, setUser] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [firmName, setFirmName] = useState('');
  const [userType, setUserType] = useState('');

  const { setAddress, setDeliveryAddress, setDeliveryMethodName, setBillingMethodName, setCurrentBillingValue, setCurrentDeliveryValue, setCurrentShippingValue, setShippingMethodName, setCurrentClientName, setCurrentClientValue } = useContext(DataContext);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await getDetailInfo(token, id);
      setDescription(data.IssueDescription);
      setResponse(data.Response);
      setDate(data.Date);
      setPosition(data.Position);
      setDevice(data.Device);
      setSerialNumber(data.SerialNumber);
      setStatusName(data.StatusName);
      setStatus(data.Status);
      setRepairerName(data.RepairerName);
      setWorkOrder(data.WorkOrder);
      setApplicationType(data);

      const dataMaster = await getMasterInfo(token, data.MasterYear, data.MasterNumber);
      setMasterStatus(dataMaster.Status);
      setMasterStatusName(dataMaster.StatusName);
      setAddress(dataMaster.Address);
      setDeliveryAddress(dataMaster.DeliveryAddress);
      setCurrentDeliveryValue(dataMaster.DeliveryMethod);
      setDeliveryMethodName(dataMaster.DeliveryMethodName);
      setCurrentBillingValue(dataMaster.BillingMethod);
      setBillingMethodName(dataMaster.BillingMethodName);
      setCurrentShippingValue(dataMaster.ShippingMethod);
      setShippingMethodName(dataMaster.ShippingMethodName);
      setCurrentClientValue(dataMaster.UserId);
      setCurrentClientName(`${dataMaster.UserName} - ${dataMaster.Address}`);

      const userData = await getUserInfo(token, userId);
      setFirmName(userData.FirmName);
      setPhone(userData.Phone);
      setEmail(userData.Email);
      setUser(userData.FirstName + ' ' + userData.LastName);
      setUserType(userData.Type);

      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  function setApplicationType(data) {
    if (data.Installation) setType((prevState) => [...prevState, 'Instalacija']);
    if (data.Warranty) setType((prevState) => [...prevState, 'Garancija']);
    if (data.NoWarranty) setType((prevState) => [...prevState, 'Van garancija']);
    if (data.FlatRate) setType((prevState) => [...prevState, 'Paušal']);
    if (data.Preventive) setType((prevState) => [...prevState, 'Preventiva']);
    if (data.ByCall) setType((prevState) => [...prevState, 'Po pozivu']);
  }

  return (
    <Container>
      {loading ? (
        <Spinner style={{ marginTop: '100px' }} />
      ) : (
        <div>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <img style={{ marginRight: '20px' }} src={nextIcon} />
            <Title>Prijava kvara</Title>
          </div>

          <MasterInfoSaved date={date} status={status} user={user} firmName={firmName} userType={userType} phone={phone} email={email} />

          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <img style={{ marginRight: '20px' }} src={nextIcon} />
            <Title>Stavka prijavljenog kvara</Title>
          </div>

          <DetailInfoSaved year={year} position={position} statusName={statusName} status={status} repairerName={repairerName} workOrder={workOrder} device={device} serialNumber={serialNumber} type={type} description={description} response={response} id={id} />
        </div>
      )}
    </Container>
  );
};

export default DetailSavedCard;
