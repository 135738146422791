import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ButtonLoading } from '../shared/CustomButton';
import {
  getClientData,
  updateClient,
  registerClient,
} from '../../services/user/client';
import { SpinnerBtn, Spinner } from '../shared/Spinner';
import { Bar, Label } from '../shared/CustomInput';
import {
  Form,
  Input,
  Row,
  Element,
  LocalError,
  RowAddress,
} from '../../assets/css/_forms';
import Error from '../shared/Error';
import { COLORS } from '../../assets/css/_colors';
import IBError from 'src/services/common/IBError';

const CustomForm = styled(Form)`
  @media (max-width: 767px) {
    max-width: 500px;
  }
`;

export const ClientForm = ({ selectedClient, edit }) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const userId = JSON.parse(localStorage.getItem('userId'));
  const history = useHistory();
  const [nameCSS, setNameCSS] = useState({ top: '30px' });
  const [oibCSS, setOibCSS] = useState({ top: '30px' });
  const [addressCSS, setAddressCSS] = useState({ top: '30px' });
  const [deliveryAddressCSS, setDeliveryAddressCSS] = useState({
    top: '30px',
  });
  const [cityCSS, setCityCSS] = useState({ top: '30px' });
  const [zipCodeCSS, setZipCodeCSS] = useState({ top: '30px' });
  const [serverError, setServerError] = useState('');
  const { register, handleSubmit, setValue, setError, errors, watch } =
    useForm();
  const [deliveryCityCSS, setDeliveryCityCSS] = useState({ top: '30px' });
  const [deliveryZipCodeCSS, setDeliveryZipCodeCSS] = useState({
    top: '30px',
  });
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);

  useEffect(() => {
    inputCleanUp();
    if (edit) fetchData();
    setLoadingForm(false);
  }, [selectedClient]);

  const inputCleanUp = () => {
    setNameCSS({ top: '30px' });
    setValue('name', '');
    setOibCSS({ top: '30px' });
    setValue('oib', '');
    setAddressCSS({ top: '30px' });
    setValue('address', '');
    setCityCSS({ top: '30px' });
    setValue('city', '');
    setZipCodeCSS({ top: '30px' });
    setValue('zipCode', '');
    setDeliveryAddressCSS({ top: '30px' });
    setValue('deliveryAddress', '');
    setDeliveryCityCSS({ top: '30px' });
    setValue('deliveryCity', '');
    setDeliveryZipCodeCSS({ top: '30px' });
    setValue('deliveryZipCode', '');
  };

  const fetchData = async () => {
    try {
      const clientData = await getClientData(token, userId, selectedClient);
      setLoadingForm(false);

      if (clientData.KomitName !== '') {
        setValue('name', clientData.KomitName);
        setNameCSS({ top: '0px' });
      }
      if (clientData.OIB1 !== '' && clientData.OIB2 !== '') {
        setValue('oib', `${clientData.OIB1}${clientData.OIB2}`);
        setOibCSS({ top: '0px' });
      } else if (clientData.OIB1 !== '') {
        setValue('oib', clientData.OIB1);
        setOibCSS({ top: '0px' });
      }
      if (clientData.Address !== '') {
        setAddressCSS({ top: '0px' });
        setValue('address', clientData.Address);
      }
      if (clientData.City !== '') {
        setCityCSS({ top: '0px' });
        setValue('city', clientData.City);
      }
      if (clientData.ZipCode !== '') {
        setZipCodeCSS({ top: '0px' });
        setValue('zipCode', clientData.ZipCode);
      }
      if (clientData.DeliveryAddress !== '') {
        setDeliveryAddressCSS({ top: '0px' });
        setValue('deliveryAddress', clientData.DeliveryAddress);
      }
      if (clientData.DeliveryCity !== '') {
        setDeliveryCityCSS({ top: '0px' });
        setValue('deliveryCity', clientData.DeliveryCity);
      }
      if (clientData.DeliveryZipCode !== '') {
        setDeliveryZipCodeCSS({ top: '0px' });
        setValue('deliveryZipCode', clientData.DeliveryZipCode);
      }
    } catch (e) {
      // NOTE: more meaningful error message ?
      throw new IBError('Greška');
    }
  };

  const onInfoSubmit = async (data) => {
    setLoading(true);
    let err = false;
    if (
      watch('address') !== '' ||
      watch('zipCode') !== '' ||
      watch('city') !== ''
    ) {
      if (watch('address') === '') {
        setError('address', {
          type: 'required',
          message: 'Unesite adresu',
        });
        err = true;
      }
      if (watch('zipCode') === '') {
        setError('zipCode', {
          type: 'required',
          message: 'Unesite poštanski broj',
        });
        err = true;
      }
      if (watch('city') === '') {
        setError('city', {
          type: 'required',
          message: 'Unesite grad',
        });
        err = true;
      }
    }
    if (
      watch('deliveryAddress') !== '' ||
      watch('deliveryZipCode') !== '' ||
      watch('deliveryCity') !== ''
    ) {
      if (watch('deliveryAddress') === '') {
        setError('deliveryAddress', {
          type: 'required',
          message: 'Unesite adresu',
        });
        err = true;
      }
      if (watch('deliveryZipCode') === '') {
        setError('deliveryZipCode', {
          type: 'required',
          message: 'Unesite poštanski broj',
        });
        err = true;
      }
      if (watch('deliveryCity') === '') {
        setError('deliveryCity', {
          type: 'required',
          message: 'Unesite grad',
        });
        err = true;
      }
    }

    if (!err) {
      try {
        let OIB1, OIB2;
        if (data.deliveryAddress === '') {
          data.deliveryAddress = data.address;
          data.deliveryCity = data.city;
          data.deliveryZipCode = data.zipCode;
        }

        if (data.oib.length > 11) {
          OIB1 = data.oib.substring(0, 11);
          OIB2 = data.oib.substring(data.oib.length - 3);
        } else {
          OIB1 = data.oib;
          OIB2 = '';
        }

        var request = {
          firmName: data.name,
          OIB1: OIB1,
          OIB2: OIB2,
          address: data.address,
          city: data.city,
          zipCode: data.zipCode,
          deliveryAddress: data.deliveryAddress,
          deliveryCity: data.deliveryCity,
          deliveryZipCode: data.deliveryZipCode,
        };

        if (edit) {
          await updateClient(token, userId, request, selectedClient);
          localStorage.setItem('actionMessage', 'Uspješna promjena lokacije!');
        } else {
          await registerClient(token, userId, request);
          localStorage.setItem(
            'actionMessage',
            'Uspješno dodavanje nove lokacije!'
          );
        }
        window.location.reload(false);
      } catch (e) {
        setServerError(e);
        setLoading(false);
      }
    } else setLoading(false);
  };

  const onChangeFunction = async (e) => {
    setServerError('');
  };

  return (
    <CustomForm onSubmit={handleSubmit(onInfoSubmit)} autoComplete="none">
      {loadingForm ? (
        <Spinner />
      ) : (
        <div>
          <Row>
            <Element
              width="95%"
              onBlur={() =>
                watch('name') === ''
                  ? setNameCSS({
                      top: '30px',
                    })
                  : setNameCSS({
                      top: '0px',
                    })
              }
              onFocus={() => setNameCSS({ top: '0px' })}
            >
              <Input
                name="name"
                type="text"
                ref={register({
                  required: {
                    value: true,
                    message: 'Unesite naziv',
                  },
                  maxLength: {
                    value: 100,
                    message: 'Naziv može sadržavati najviše 100 znakova',
                  },
                })}
                onChange={(e) => setServerError('')}
                autoComplete="off"
              />
              <Bar />
              <Label style={nameCSS} text="Naziv" />
              {errors.name && <LocalError>{errors.name.message}</LocalError>}
            </Element>
            <Element
              width="95%"
              style={{ justifySelf: 'end' }}
              onBlur={() =>
                watch('oib') === ''
                  ? setOibCSS({
                      top: '30px',
                    })
                  : setOibCSS({
                      top: '0px',
                    })
              }
              onFocus={() => setOibCSS({ top: '0px' })}
            >
              <Input
                name="oib"
                type="text"
                ref={register({
                  required: {
                    value: true,
                    message: 'Unesite OIB',
                  },
                  maxLength: {
                    value: 14,
                    message: 'OIB sadrži 14 brojeva',
                  },
                  // length: {
                  //     value: 11,
                  //     message: 'OIB sadrži 11 brojeva',
                  // },
                  // length: {
                  //     value: 14,
                  //     message: 'OIB sadrži 11 brojeva',
                  // },
                  minLength: {
                    value: 11,
                    message: 'OIB sadrži 11 brojeva',
                  },
                  pattern: {
                    value: /^[0-9\b]+$/,
                    message: 'OIB mora sadržavati samo brojeve',
                  },
                })}
                onChange={(e) => setServerError('')}
                autoComplete="off"
              />
              <Bar />
              <Label style={oibCSS} text="OIB" />
              {errors.oib && <LocalError>{errors.oib.message}</LocalError>}
            </Element>
          </Row>

          <RowAddress>
            <Element
              width="95%"
              onBlur={() =>
                watch('address') === ''
                  ? setAddressCSS({
                      top: '30px',
                    })
                  : setAddressCSS({
                      top: '0px',
                    })
              }
              onFocus={() => setAddressCSS({ top: '0px' })}
            >
              <Input
                name="address"
                type="text"
                ref={register({
                  required: {
                    value: true,
                    message: 'Unesite adresu',
                  },
                  maxLength: {
                    value: 100,
                    message: 'Adresa može sadržavati najviše 50 znakova',
                  },
                })}
                onChange={(e) => setServerError('')}
                autoComplete="off"
              />
              <Bar />
              <Label style={addressCSS} text="Adresa" />
              {errors.address && (
                <LocalError>{errors.address.message}</LocalError>
              )}
            </Element>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '40% auto',
              }}
            >
              <Element
                width="95%"
                onBlur={() =>
                  watch('zipCode') === ''
                    ? setZipCodeCSS({
                        top: '30px',
                      })
                    : setZipCodeCSS({
                        top: '0px',
                      })
                }
                onFocus={() => setZipCodeCSS({ top: '0px' })}
              >
                <Input
                  name="zipCode"
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: 'Unesite poštanski broj',
                    },
                    minLength: {
                      value: 5,
                      message: 'Poštanski broj sadrži 5 znamenki',
                    },
                    maxLength: {
                      value: 5,
                      message: 'Poštanski broj sadrži 5 znamenki',
                    },
                    pattern: {
                      value: /^[0-9\b]+$/,
                      message: 'Poštanski broj mora sadržavati samo brojeve',
                    },
                  })}
                  onChange={(e) => setServerError('')}
                  autoComplete="none"
                />
                <Bar />
                <Label style={zipCodeCSS} text="Poštanski broj" />
                {errors.zipCode && (
                  <LocalError>{errors.zipCode.message}</LocalError>
                )}
              </Element>
              <Element
                width="95%"
                style={{ justifySelf: 'end' }}
                onBlur={() =>
                  watch('city') === ''
                    ? setCityCSS({
                        top: '30px',
                      })
                    : setCityCSS({
                        top: '0px',
                      })
                }
                onFocus={() => setCityCSS({ top: '0px' })}
              >
                <Input
                  name="city"
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: 'Unesite grad',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Mjesto može sadržavati najviše 50 znakova',
                    },
                  })}
                  onChange={(e) => setServerError('')}
                  autoComplete="none"
                />
                <Bar />
                <Label style={cityCSS} text="Grad" />
                {errors.city && <LocalError>{errors.city.message}</LocalError>}
              </Element>
            </div>
          </RowAddress>

          <RowAddress>
            <Element
              width="95%"
              onBlur={() =>
                watch('deliveryAddress') === ''
                  ? setDeliveryAddressCSS({
                      top: '30px',
                    })
                  : setDeliveryAddressCSS({
                      top: '0px',
                    })
              }
              onFocus={() => setDeliveryAddressCSS({ top: '0px' })}
            >
              <Input
                name="deliveryAddress"
                type="text"
                ref={register({
                  maxLength: {
                    value: 100,
                    message:
                      'Dostavna adresa može sadržavati najviše 50 znakova',
                  },
                })}
                onChange={onChangeFunction}
                autoComplete="none"
              />
              <Bar />
              <Label style={deliveryAddressCSS} text="Dostavna adresa" />
              {errors.deliveryAddress && (
                <LocalError>{errors.deliveryAddress.message}</LocalError>
              )}
            </Element>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '40% auto',
              }}
            >
              <Element
                width="95%"
                onBlur={() =>
                  watch('deliveryZipCode') === ''
                    ? setDeliveryZipCodeCSS({
                        top: '30px',
                      })
                    : setDeliveryZipCodeCSS({
                        top: '0px',
                      })
                }
                onFocus={() => setDeliveryZipCodeCSS({ top: '0px' })}
              >
                <Input
                  name="deliveryZipCode"
                  type="text"
                  ref={register({
                    maxLength: {
                      value: 5,
                      message: 'Poštanski broj sadrži 5 znamenki',
                    },
                    minLength: {
                      value: 5,
                      message: 'Poštanski broj sadrži 5 znamenki',
                    },
                    pattern: {
                      value: /^[0-9\b]+$/,
                      message: 'Poštanski broj mora sadržavati samo brojeve',
                    },
                  })}
                  onChange={(e) => setServerError('')}
                  autoComplete="none"
                />
                <Bar />
                <Label style={deliveryZipCodeCSS} text="Poštanski broj" />
                {errors.deliveryZipCode && (
                  <LocalError>{errors.deliveryZipCode.message}</LocalError>
                )}
              </Element>
              <Element
                width="95%"
                style={{ justifySelf: 'end' }}
                onBlur={() =>
                  watch('deliveryCity') === ''
                    ? setDeliveryCityCSS({
                        top: '30px',
                      })
                    : setDeliveryCityCSS({
                        top: '0px',
                      })
                }
                onFocus={() => setDeliveryCityCSS({ top: '0px' })}
              >
                <Input
                  name="deliveryCity"
                  type="text"
                  ref={register({
                    maxLength: {
                      value: 50,
                      message: 'Mjesto može sadržavati najviše 50 znakova',
                    },
                  })}
                  onChange={(e) => setServerError('')}
                  autoComplete="none"
                />
                <Bar />
                <Label style={deliveryCityCSS} text="Grad" />
                {errors.deliveryCity && (
                  <LocalError>{errors.deliveryCity.message}</LocalError>
                )}
              </Element>
            </div>
          </RowAddress>

          <ButtonLoading
            style={
              loading
                ? {
                    backgroundColor: 'transparent',
                    color: `${COLORS.green}`,
                    border: `2px solid ${COLORS.green}`,
                    paddingTop: '0',
                  }
                : null
            }
            type="submit"
          >
            {loading && <SpinnerBtn />}
            Spremi
          </ButtonLoading>

          {serverError !== '' && <Error msg={serverError} />}

          <Input
            style={{ display: 'none' }}
            name="off"
            type="text"
            autoComplete="off"
          />
        </div>
      )}
    </CustomForm>
  );
};
