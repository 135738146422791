import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../assets/css/_colors';

const StyledBurger = styled.div`
    top: 0;
    right: 0;
    position: fixed;
    margin: 10px;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 27px;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 101;
    margin-right: 10px;

    &:focus {
        outline: none;
    }

    @media (max-width: 767px) {
        display: flex;
    }

    div {
        width: 30px;
        height: 2px;
        background: ${(props) => (props.open ? 'white' : `${COLORS.grayDark}`)};
        border-radius: 15px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;

        :first-child {
            transform: ${({ open }) => (open ? 'rotate(40deg)' : 'rotate(0)')};
        }

        :nth-child(2) {
            opacity: ${({ open }) => (open ? '0' : '1')};
            transform: ${({ open }) =>
                open ? 'translateX(20px)' : 'translateX(0)'};
        }

        :nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-40deg)' : 'rotate(0)')};
        }
    }
`;

const Burger = (props) => {
    function onBurgerClick(e) {
        props.open
            ? (document.body.style.overflow = 'scroll')
            : (document.body.style.overflow = 'hidden');

        props.setOpen(!props.open);
    }

    return (
        <StyledBurger open={props.open} onClick={onBurgerClick}>
            <div />
            <div />
            <div />
        </StyledBurger>
    );
};

export default Burger;
