import React, { useEffect } from 'react';
import { ReactNotifications, store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { NotificationCard } from './NotificationCard';

const Notification = ({}) => {
    const actionMessage = localStorage.getItem('actionMessage');

    useEffect(() => {
        if (actionMessage !== '' && actionMessage !== null) {
            showNotification();
            setTimeout(() => {
                localStorage.removeItem('actionMessage');
            }, 3500);
        }
    }, []);

    const showNotification = () => {
        store.addNotification({
            content: NotificationCard,
            type: 'success',
            className: 'notification__custom--success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
                duration: 3000,
                showIcon: true,
            },
        });
    };

    return <ReactNotifications />;
};

export default Notification;