import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLORS } from '../../assets/css/_colors';

const Container = styled.div`
    text-align: center;
    margin: 10px 10px 0 10px;
    color: ${COLORS.grayDark};
`;

const RegisterLink = styled.span`
    margin-left: 5px;
    color: rgb(23, 139, 23);
    cursor: pointer;
    text-decoration: underline;
`;

export const LinkForm = ({ text, linkText, to }) => {
    return (
        <Container>
            {text}
            <Link to={to}>
                <RegisterLink>{linkText}</RegisterLink>
            </Link>
        </Container>
    );
};
