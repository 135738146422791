import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../assets/css/_colors';

const Card = styled.div`
    border: 1px solid ${COLORS.grayLight};
    border-radius: 4px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 2;

    .element {
        font-weight: bold;
        color: ${COLORS.green};
        padding: 20px 40px;
        margin-bottom: 0;

        @media (max-width: 767px) {
            padding: 20px;
        }

        @media (max-width: 460px) {
            &__desktop {
                display: none;
            }
        }
    }
`;

const MasterCard = ({ master }) => {
    return (
        <Card>
            <div className='element'>
                <label>Datum: </label>
                <label style={{ color: 'black' }}>{master.Date}</label>
            </div>

            <div className='element element__desktop'>
                <label>Godina: </label>
                <label style={{ color: 'black' }}>{master.Year}</label>
                <label style={{ marginLeft: '20px' }}>Broj: </label>
                <label style={{ color: 'black' }}>{master.Number}</label>
            </div>

            <div className='element element__desktop'>
                <label>Satus: </label>
                <label style={{ color: 'black' }}>{master.StatusName}</label>
            </div>
            <div className='element'>
                <label>Lokacija: </label>
                <label style={{ color: 'black' }}>{master.UserName}</label>
            </div>
        </Card>
    );
};

export default MasterCard;
