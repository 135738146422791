import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from '../../assets/css/_colors';

const Container = styled.div`
    text-align: center;
    border-top: 1px solid ${COLORS.grayLight};
    width: 100%;
    padding: 0 40px;
    margin-top: 50px;
    transition: all 1s ease;

    @media (max-width: 767px) {
        padding: 0;
    }
`;

export const FooterForm = ({ link, text, style }) => {
    return (
        <Container style={style}>
            <p style={{ color: `${COLORS.grayDark}`, margin: '15px 30px' }}>
                {text}
                {!!link && (
                    <Link
                        to='/credentials/recover'
                        style={{
                            marginLeft: '5px',
                            color: `${COLORS.green}`,
                            cursor: 'pointer',
                            textDecoration: 'underline',
                        }}
                    >
                        Klikni ovdje!
                    </Link>
                )}
            </p>
        </Container>
    );
};
