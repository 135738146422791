import { API_URL } from '../../constants/index';

export async function getDetailInfo(token, id) {
  try {
    const res = await fetch(`${API_URL}/issue/detail/info/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Token: token,
      },
    });
    if (res.status === 200) {
      const responseData = await res.json();
      return responseData;
    } else {
      const json = await res.json();
      const response = {
        Message: json.Message,
        Status: res.status,
      };
      throw response;
    }
  } catch (e) {
    throw e;
  }
}

export async function deleteDetail(token, id) {
  try {
    const res = await fetch(`${API_URL}/issue/detail/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Token: token,
      },
    });
    if (res.status === 200) {
      const responseData = await res.json();
      return responseData;
    } else {
      const json = await res.json();
      const response = {
        Message: json.Message,
        Status: res.status,
      };
      throw response;
    }
  } catch (e) {
    throw e;
  }
}

export async function cancelDetail(token, id) {
  try {
    const res = await fetch(`${API_URL}/issue/detail/cancel/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Token: token,
      },
    });
    if (res.status === 200) {
      const responseData = await res.json();
      return responseData;
    } else {
      const json = await res.json();
      const response = {
        Message: json.Message,
        Status: res.status,
      };
      throw response;
    }
  } catch (e) {
    throw e;
  }
}

export async function updateDetail(dataSet) {
  try {
    const res = await fetch(`${API_URL}/issue/detail/${dataSet.Id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Token: dataSet.Token,
      },
      body: JSON.stringify({
        MasterNumber: dataSet.Number,
        MasterYear: dataSet.Year,
        IssueDescription: dataSet.Description,
        Device: dataSet.Device,
        SerialNumber: dataSet.SerialNumber,
        Installation: dataSet.Installation,
        Warranty: dataSet.Warranty,
        NoWarranty: dataSet.NoWarranty,
        FlatRate: dataSet.FlatRate,
        Preventive: dataSet.Preventive,
        ByCall: dataSet.ByCall,
      }),
    });
    if (res.status === 200) {
      const responseData = await res.json();
      return responseData;
    } else {
      const json = await res.json();
      const response = {
        Message: json.Message,
        Status: res.status,
      };
      throw response;
    }
  } catch (e) {
    throw e;
  }
}

export async function registerDetail(dataSet) {
  try {
    const res = await fetch(`${API_URL}/issue/detail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Token: dataSet.Token,
      },
      body: JSON.stringify({
        MasterNumber: dataSet.Number,
        MasterYear: dataSet.Year,
        IssueDescription: dataSet.Description,
        Device: dataSet.Device,
        SerialNumber: dataSet.SerialNumber,
        Installation: dataSet.Installation,
        Warranty: dataSet.Warranty,
        NoWarranty: dataSet.NoWarranty,
        FlatRate: dataSet.FlatRate,
        Preventive: dataSet.Preventive,
        ByCall: dataSet.ByCall,
      }),
    });
    if (res.status === 200) {
      const responseData = await res.json();
      return responseData;
    } else {
      const json = await res.json();
      const response = {
        Message: json.Message,
        Status: res.status,
      };
      throw response;
    }
  } catch (e) {
    throw e;
  }
}

export async function getDetailListByStatus(
  token,
  year,
  number,
  status,
  device
) {
  try {
    const res = await fetch(
      `${API_URL}/issue/detail/list/${status}/${year}/${number}/${
        device ? device : 0
      }`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },
      }
    );
    if (res.status === 200) {
      const responseData = await res.json();

      return responseData;
    } else {
      const json = await res.json();
      const response = {
        Message: json.Message,
        Status: res.status,
      };
      throw response;
    }
  } catch (e) {
    throw e;
  }
}
