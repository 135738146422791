import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../assets/css/_colors';
import editIcon from '../../assets/images/edit.svg';

export const Input = styled.input`
  width: 100%;
  border: 0;
  border-bottom: 1px solid ${COLORS.grayLight};
  display: block;
  height: 40px;
  outline: none;
  font-size: 17px;
  background-color: transparent !important;
  color: ${COLORS.grayTextDark};

  &:focus ~ .bar::before,
  &:focus ~ .bar::after {
    width: 50%;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  border: 0;
  border-bottom: 1px solid ${COLORS.grayLight};
  color: ${COLORS.grayTextDark};
  display: block;
  outline: none;
  font-size: 17px;
  background-color: transparent !important;
  resize: none;

  &:focus ~ .bar::before,
  &:focus ~ .bar::after {
    width: 50%;
  }
`;

const BarContainer = styled.span`
  position: absolute;
  display: block;
  width: 100%;
  &:before,
  &:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: ${COLORS.green};
    transition: all 0.2s ease;
  }
  &:before {
    left: 50%;
  }
  &:after {
    right: 50%;
  }
`;

const LabelContainer = styled.label`
  position: absolute;
  top: 30px;
  transition: all 0.2s ease;
  font-size: 16px;
  color: ${COLORS.green};
  z-index: -1;
`;

export const EditIcon = styled.img`
  width: 15px;
  position: absolute;
  right: 0;
  transition: all 0.2s ease;
  font-size: 16px;
  color: ${COLORS.green};
  z-index: -1;
`;

export const Bar = ({ style }) => {
  return <BarContainer style={style} className="bar" />;
};

export const Label = ({ style, text }) => {
  return <LabelContainer style={style}>{text}</LabelContainer>;
};

export const LabelEdit = ({ iconStyle, style, text }) => {
  return (
    <div>
      <LabelContainer style={style}>{text}</LabelContainer>
      <EditIcon style={style} src={editIcon} />
    </div>
  );
};
