import React, { useEffect } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../assets/css/_colors';
import errorPageIcon from '../../assets/images/errorPageIcon.svg';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: ${COLORS.grayLightBackground};
  position: absolute;
  z-index: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    text-decoration: underline;
    color: ${COLORS.grayTextDark};
  }

  p {
    color: ${COLORS.grayTextLight};
  }
`;

const ErrorPage = ({}) => {
  useEffect(() => {
    // NOTE: confirm is this really neccesary?
    //localStorage.removeItem('token');
    //localStorage.removeItem('userId');
    //localStorage.removeItem('actionMessage');
    //localStorage.removeItem('firm');
  }, []);

  return (
    <Wrapper>
      <img
        style={{ width: '200px', marginBottom: '50px' }}
        src={errorPageIcon}
      />
      <p style={{ fontSize: '20px' }}>Dogodila se greška na serveru!</p>
      <p style={{ textAlign: 'center' }}>
        Možete nas kontaktirati na broj <span>(01) 2409-140</span> <br></br>ili
        e-mail <span>infodata@infodata.hr</span>
      </p>
    </Wrapper>
  );
};

export default ErrorPage;
