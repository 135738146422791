import React, { useState } from 'react';
import MasterList from '../IssueReport/Master/MasterList';
import FilterStatus from '../shared/FilterStatus';
import { Container } from '../../assets/css/_pages';
import { Title } from '../../assets/css/_elements';
import PageLayout from '../common/layout/PageLayout';

const HomePage = ({}) => {
  const [status, setStatus] = useState(100);
  const [client, setClient] = useState(100);
  const [device, setDevice] = useState('');
  const [loading, setLoading] = useState(true);

  return (
    <PageLayout>
      <Container>
        <Title style={{ marginBottom: '40px' }}>Prijavljeni kvarovi</Title>

        <FilterStatus
          setStatus={setStatus}
          setClient={setClient}
          setDevice={setDevice}
          client={client}
          status={status}
          device={device}
          loading={loading}
          setLoading={setLoading}
        />

        <MasterList
          key={status}
          loading={loading}
          setLoading={setLoading}
          status={status}
          client={client}
          device={device}
        />
      </Container>
    </PageLayout>
  );
};

export default HomePage;
