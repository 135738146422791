import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { registerMaster, updateMaster } from '../../services/issue/issueMaster';
import { COLORS } from '../../assets/css/_colors';
import { DataContext } from '../../context/MasterDataContext';
import {
  getDetailInfo,
  updateDetail,
  registerDetail,
  cancelDetail,
} from '../../services/issue/issueDetail';
import { ButtonBasic } from '../shared/CustomButton';
import { DialogWarning } from '../shared/Dialog';
import { SpinnerSmall } from '../shared/Spinner';
import Error from '../shared/Error';
import {
  Bar,
  LabelEdit,
  EditIcon,
  Input,
  Textarea,
} from '../shared/CustomInput';
import editIcon from '../../assets/images/edit.svg';
import cancelIcon from '../../assets/images/cancel.svg';
import deleteIcon from '../../assets/images/delete.svg';
import saveIcon from '../../assets/images/save.svg';

const Form = styled.form`
  width: 100%;
  margin: 0;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.checkboxes ? 'repeat(6, auto)' : 'repeat(2, 50%)'};
  margin-top: 20px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 50%);
  }

  @media (max-width: 480px) {
    grid-template-columns: 100%;
  }
`;

const Buttons = styled.div`
  display: flex;
  padding: 20px 0;

  @media (max-width: 767px) {
    padding: 20px 0;
  }

  @media (max-width: 480px) {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
`;

const Element = styled.div`
  width: ${(props) => props.width};
  position: relative;
  padding: 15px 0;

  @media (max-width: 767px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const ErrorLocal = styled.p`
  color: ${COLORS.red};
  font-size: 13px;
  margin-top: ${(props) => (props.description ? '0' : '13px')};
`;

const DetailForm = ({ edit }) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const userId = JSON.parse(localStorage.getItem('userId'));
  let { year, number, id } = useParams();
  const { register, handleSubmit, setValue, errors, watch } = useForm();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [deviceCSS, setDeviceCSS] = useState({ top: '30px' });
  const [serialNumberCSS, setSerialNumberCSS] = useState({ top: '30px' });
  const [installation, setInstallation] = useState(false);
  const [warranty, setWarranty] = useState(false);
  const [noWarranty, setNoWarranty] = useState(false);
  const [flatRate, setFlatRate] = useState(false);
  const [preventive, setPreventive] = useState(false);
  const [byCall, setByCall] = useState(false);
  const [position, setPosition] = useState('');
  const [statusName, setStatusName] = useState('');
  const [showResponse, setShowResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const {
    address,
    deliveryAddress,
    deliveryMethodName,
    billingMethodName,
    currentBillingValue,
    currentDeliveryValue,
    currentShippingValue,
    shippingMethodName,
    currentClientValue,
  } = useContext(DataContext);

  useEffect(() => {
    if (edit) fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await getDetailInfo(token, id);
      data.Response ? setShowResponse(true) : setShowResponse(false);
      setValue('repairerResponse', data.Response);
      setValue('description', data.IssueDescription);
      setPosition(data.Position);
      setStatusName(data.StatusName);
      setInstallation(data.Installation);
      setWarranty(data.Warranty);
      setNoWarranty(data.NoWarranty);
      setFlatRate(data.FlatRate);
      setPreventive(data.Preventive);
      setByCall(data.ByCall);
      if (data.SerialNumber !== '') {
        setSerialNumberCSS({ top: '0px' });
        setValue('serialNumber', data.SerialNumber);
      }
      if (data.Device !== '') {
        setDeviceCSS({ top: '0px' });
        setValue('device', data.Device);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const confirmDetailDelete = async () => {
    try {
      await cancelDetail(token, id);
      localStorage.setItem(
        'actionMessage',
        'Uspješno brisanje prijavljenog kvara!'
      );
      history.push(`/`);
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmitForm = async (data) => {
    let detailData = {
      Number: number,
      Year: year,
      Token: token,
      Id: id,
      Installation: installation,
      Warranty: warranty,
      NoWarranty: noWarranty,
      FlatRate: flatRate,
      Preventive: preventive,
      ByCall: byCall,
      Description: data.description,
      Device: data.device,
      SerialNumber: data.serialNumber,
    };
    let masterData = {
      Token: token,
      UserId: userId,
      Address: address,
      DeliveryAddress: deliveryAddress,
      DeliveryMethod: currentDeliveryValue,
      DeliveryMethodName: deliveryMethodName,
      BillingMethod: currentBillingValue,
      BillingMethodName: billingMethodName,
      ShippingMethod: currentShippingValue,
      ShippingMethodName: shippingMethodName,
      ClientId: currentClientValue,
    };

    try {
      setLoading(true);
      if (edit) {
        await updateMaster(masterData, detailData.Year, detailData.Number);
        await updateDetail(detailData);
        localStorage.setItem(
          'actionMessage',
          'Uspješna promjena prijave kvara!'
        );
        history.push('/');
      } else {
        if (number === undefined) {
          const response = await registerMaster(masterData);
          detailData.Year = response.Year;
          detailData.Number = response.Number;

          await registerDetail(detailData);
          localStorage.setItem('actionMessage', 'Uspješno je dodan novi kvar!');
          history.push(`/detail/${detailData.Year}/${detailData.Number}`);
        } else {
          await updateMaster(masterData, detailData.Year, detailData.Number);

          await registerDetail(detailData);
          localStorage.setItem('actionMessage', 'Uspješno je dodan novi kvar!');
          window.location.reload(false);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitForm)}>
      {edit && (
        <Row>
          <Element
            width="95%"
            style={{
              borderBottom: `1px solid ${COLORS.grayLight}`,
              color: `${COLORS.green}`,
            }}
          >
            <label>Redni broj: </label>
            <label style={{ color: `${COLORS.grayTextLight}` }}>
              {position}
            </label>
          </Element>
          <Element
            width="95%"
            style={{
              justifySelf: 'end',
              borderBottom: `1px solid ${COLORS.grayLight}`,
              color: `${COLORS.green}`,
            }}
          >
            <label>Status: </label>
            <label style={{ color: `${COLORS.grayTextLight}` }}>
              {statusName}
            </label>
          </Element>
        </Row>
      )}
      <Row>
        <Element
          width="95%"
          onFocus={() => setDeviceCSS({ top: '0px' })}
          onBlur={() =>
            watch('device') === ''
              ? setDeviceCSS({ top: '30px' })
              : setDeviceCSS({ top: '0px' })
          }
        >
          <Input
            name="device"
            type="text"
            ref={register({
              required: {
                value: true,
                message: 'Unesite opis kvara',
              },
              maxLength: {
                value: 60,
                message: 'Maksimalno 60 znakova',
              },
            })}
            autoComplete="none"
          />
          <Bar />
          <LabelEdit style={deviceCSS} text="Uređaj" />
          {errors.device && <ErrorLocal>{errors.device.message}</ErrorLocal>}
        </Element>
        <Element
          width="95%"
          style={{ justifySelf: 'end' }}
          onFocus={() => setSerialNumberCSS({ top: '0px' })}
          onBlur={() =>
            watch('serialNumber') === ''
              ? setSerialNumberCSS({ top: '30px' })
              : setSerialNumberCSS({ top: '0px' })
          }
        >
          <Input
            name="serialNumber"
            type="text"
            ref={register({
              maxLength: {
                value: 60,
                message: 'Maksimalno 60 znakova',
              },
            })}
            autoComplete="none"
          />
          <Bar />
          <LabelEdit style={serialNumberCSS} text="Serijski broj" />
          {errors.serialNumber && (
            <ErrorLocal>{errors.serialNumber.message}</ErrorLocal>
          )}
        </Element>
      </Row>
      <p style={{ color: `${COLORS.green}`, margin: '10px 0' }}>
        Vrsta prijave
      </p>
      <Row checkboxes>
        <div style={{ marginRight: '10px', marginBottom: '10px' }}>
          <input
            type="checkbox"
            checked={installation}
            onChange={(e) => setInstallation(!installation)}
            value={installation}
          />
          <label>Instalacija</label>
        </div>
        <div style={{ marginRight: '10px', marginBottom: '10px' }}>
          <input
            type="checkbox"
            checked={warranty}
            onChange={(e) => setWarranty(!warranty)}
            value={warranty}
          />
          <label>Garancija</label>
        </div>
        <div style={{ marginRight: '10px', marginBottom: '10px' }}>
          <input
            type="checkbox"
            checked={noWarranty}
            onChange={(e) => setNoWarranty(!noWarranty)}
            value={noWarranty}
          />
          <label>Van garancije</label>
        </div>
        <div style={{ marginRight: '10px', marginBottom: '10px' }}>
          <input
            type="checkbox"
            checked={flatRate}
            onChange={(e) => setFlatRate(!flatRate)}
            value={flatRate}
          />
          <label>Paušal</label>
        </div>
        <div style={{ marginRight: '10px', marginBottom: '10px' }}>
          <input
            type="checkbox"
            checked={preventive}
            onChange={(e) => setPreventive(!preventive)}
            value={preventive}
          />
          <label>Preventiva</label>
        </div>
        <div style={{ marginRight: '10px', marginBottom: '10px' }}>
          <input
            type="checkbox"
            checked={byCall}
            onChange={(e) => setByCall(!byCall)}
            value={byCall}
          />
          <label>Po pozivu</label>
        </div>
      </Row>

      {!showResponse ? (
        <Row style={{ gridTemplateColumns: '100%' }}>
          <Element width="100%">
            <p
              style={{
                marginTop: '0',
                marginBottom: '10px',
                color: `${COLORS.green}`,
              }}
            >
              Opis kvara
              <EditIcon style={{ marginTop: '0' }} src={editIcon} />
            </p>
            <Textarea
              rows="5"
              placeholder="Opišite kvar koji imate..."
              name="description"
              type="text"
              ref={register({
                required: {
                  value: true,
                  message: 'Unesite opis kvara',
                },
                maxLength: {
                  value: 512,
                  message: 'Maksimalno 512 znakova',
                },
              })}
              autoComplete="none"
            />
            <Bar />
          </Element>
          {errors.description && (
            <ErrorLocal description>{errors.description.message}</ErrorLocal>
          )}
        </Row>
      ) : (
        <Row>
          <Element width="95%">
            <p
              style={{
                marginTop: '0',
                marginBottom: '10px',
                color: `${COLORS.green}`,
              }}
            >
              Opis kvara
            </p>
            <Textarea
              rows="5"
              placeholder="Opišite kvar koji imate..."
              name="description"
              type="text"
              ref={register({
                required: {
                  value: true,
                  message: 'Unesite opis kavar',
                },
                maxLength: {
                  value: 512,
                  message: 'Maksimalno 512 znakova',
                },
              })}
              autoComplete="none"
            />
            <Bar />
          </Element>
          {errors.description && (
            <ErrorLocal description>{errors.description.message}</ErrorLocal>
          )}
          <Element width="95%">
            <p
              style={{
                marginTop: '0',
                marginBottom: '10px',
                color: `${COLORS.green}`,
              }}
            >
              Odgovor
            </p>
            <Textarea
              disabled
              rows="5"
              name="repairerResponse"
              type="text"
              ref={register}
            />
            <Bar />
          </Element>
        </Row>
      )}

      <Buttons style={{ marginTop: '20px' }}>
        {!edit ? (
          <ButtonBasic
            style={{
              marginRight: '5px',
            }}
            type="button"
            onClick={() => history.push(`/`)}
          >
            <img src={cancelIcon} />
            <span
              style={{
                marginLeft: '10px',
                alignSelf: 'center',
              }}
            >
              Odustani
            </span>
          </ButtonBasic>
        ) : (
          <ButtonBasic
            style={{
              marginRight: '5px',
            }}
            type="button"
            onClick={() => setOpen(true)}
          >
            <img src={deleteIcon} />
            <span
              style={{
                marginLeft: '10px',
                alignSelf: 'center',
              }}
            >
              Obriši
            </span>
          </ButtonBasic>
        )}
        <ButtonBasic
          style={{
            marginLeft: '5px',
          }}
          type="submit"
        >
          <img src={saveIcon} />
          <span
            style={{
              marginLeft: '10px',
              alignSelf: 'center',
            }}
          >
            Spremi
          </span>
        </ButtonBasic>
        {loading && <SpinnerSmall />}
      </Buttons>

      {(errors.description || errors.device) && (
        <Error msg="Unesite sva obvezna polja!" />
      )}

      <DialogWarning
        open={open}
        onClose={() => setOpen(false)}
        title={'Upozorenje!'}
        message={'Jeste li sigurni da želite obrisati kvar?'}
        onClickCancel={() => setOpen(false)}
        onClickOk={confirmDetailDelete}
        cancelText={'odustani'}
        okText={'obriši'}
      />

      <Input
        style={{ display: 'none' }}
        name="off"
        type="text"
        autoComplete="off"
      />
    </Form>
  );
};
export default DetailForm;
