import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import 'react-notifications-component/dist/theme.css';
import { Spinner } from '../../shared/Spinner';
import { getClientList } from '../../../services/user/client';
import useSWR from 'swr';
import ClientCard from './ClientCard';
import IBError from 'src/services/common/IBError';

const LocationList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  justify-items: center;

  @media (max-width: 780px) {
    grid-template-columns: 100%;
  }
`;

const Location = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

const ClientList = ({ selectedClient, setSelectedClient }) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const userId = JSON.parse(localStorage.getItem('userId'));

  const history = useHistory();

  const fetchClientList = () => {
    return getClientList(token, userId);
  };

  const { data, error } = useSWR(`/user/client/list`, fetchClientList);

  useEffect(() => {
    if (data) setSelectedClient(data[0].Id);
  }, [data]);

  if (error) {
    // NOTE: More meaningful error message?
    throw new IBError('Greška');
  }

  if (!data) {
    return (
      <div style={{ marginBottom: '200px' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      {data.length === 1 ? (
        <Location>
          <ClientCard
            lastClient={true}
            selectedClient={data[0].Id}
            setSelectedClient={setSelectedClient}
            client={data[0]}
          />
        </Location>
      ) : (
        <LocationList>
          {data.map((client) => {
            if (client.Id === selectedClient)
              return (
                <ClientCard
                  key={client.Id}
                  selectedClient={client.Id}
                  setSelectedClient={setSelectedClient}
                  client={client}
                />
              );
            else
              return (
                <ClientCard
                  key={client.Id}
                  setSelectedClient={setSelectedClient}
                  client={client}
                />
              );
          })}
        </LocationList>
      )}
    </div>
  );
};

export default ClientList;
