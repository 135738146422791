import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../assets/css/_colors';
import arrowUpIcon from '../../assets/images/arrow_up.svg';
import plusCircleIcon from '../../assets/images/plus_circle.svg';

export const ButtonBasic = styled.button`
  width: auto;
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: 0.02857em;
  cursor: pointer;
  transition: all 0.4s ease;
  text-transform: uppercase;
  font-size: 0.875rem;
  padding: 10px 16px;
  color: ${COLORS.grayTextDark};
  border: 1px solid ${COLORS.grayLight};
  background-color: transparent;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;

  :hover {
    background-color: ${COLORS.grayLight};
    color: white;
  }
`;

const BtnLoading = styled(ButtonBasic)`
  margin-top: 20px;
  width: 100%;
  display: block;
  background-color: ${COLORS.green};
  color: white;
  border: 2px solid ${COLORS.green};

  img {
    position: absolute;
  }

  :hover {
    background-color: transparent !important;
    color: green !important;
    border: 2px solid ${COLORS.green} !important;
  }
`;

const BtnUp = styled.button`
  position: fixed;
  bottom: 0;
  right: 0;
  border: none;
  background-color: transparent;
  margin-right: 0px;
  margin-bottom: 40px;
  cursor: pointer;
  z-index: 10;
`;

const BtnAddClient = styled.button`
  position: fixed;
  bottom: 0;
  right: 0;
  border: none;
  background-color: transparent;
  margin-right: 0px;
  margin-bottom: 100px;
  cursor: pointer;
  z-index: 10;
  display: flex;

  span {
    color: ${COLORS.grayTextDark};
    margin-right: 10px;
    align-self: center;
    text-transform: uppercase;
  }
`;

const BtnPdf = styled.button`
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: 0.02857em;
  cursor: pointer;
  border: none;
  background-color: ${COLORS.green};
  margin-left: 10px;
  cursor: pointer;
  font-size: 0.875rem;
  color: white;
  padding: 10px 16px;
  transition: all 0.4s ease;

  :hover {
    opacity: 0.8;
  }
`;

const BtnPDF = styled.a`
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: 0.02857em;
  cursor: pointer;
  border: none;
  background-color: ${COLORS.green};
  margin-left: 10px;
  cursor: pointer;
  font-size: 0.875rem;
  color: white;
  padding: 10px 16px;
  transition: all 0.4s ease;
  text-decoration: none;

  :hover {
    opacity: 0.8;
  }
`;

export const ButtonLoading = ({ children, type, onClick, style }) => {
  return (
    <BtnLoading style={style} onClick={onClick} type={type}>
      {children}
    </BtnLoading>
  );
};

export const ButtonUp = ({ type, onClick, style }) => {
  return (
    <BtnUp style={style} onClick={onClick} type={type}>
      <img alt="go up" src={arrowUpIcon} />
    </BtnUp>
  );
};

export const ButtonAddClient = ({ type, onClick, style }) => {
  return (
    <BtnAddClient style={style} onClick={onClick} type={type}>
      {/* <span>Dodaj komitenta</span> */}
      <img src={plusCircleIcon} />
    </BtnAddClient>
  );
};

export const ButtonPdf = ({ type, onClick, style }) => {
  return (
    <BtnPdf style={style} onClick={onClick} type={type}>
      DOWNLOAD PDF
    </BtnPdf>
  );
};

export const ButtonPDF = ({ href, style, download }) => {
  return (
    <BtnPDF style={style} href={href} download={download}>
      DOWNLOAD PDF
    </BtnPDF>
  );
};
