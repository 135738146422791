import React, { useContext, useState, useEffect } from 'react';
export const AuthContext = React.createContext();

const storeTask = (items) => {
    localStorage.setItem('token', JSON.stringify(items.token));
    localStorage.setItem('userId', JSON.stringify(items.userId));
};

const readStorage = () => {
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    return { token, userId };
};

export const AuthProvider = ({ children }) => {
    const storedTask = readStorage();
    const [token, setToken] = useState(storedTask.token);
    const [userId, setUserId] = useState(storedTask.userId);

    useEffect(() => {
        storeTask({ token, userId });
    }, [token]);

    const login = (id, token) => {
        setUserId(id);
        setToken(token);
    };

    const logout = () => {
        setUserId(null);
        setToken(null);
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('actionMessage');
        localStorage.removeItem('firm');
    };

    return (
        <AuthContext.Provider value={{ token, userId, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
