import { API_URL } from '../../constants/index';

export async function getUserInfo(token, userId) {
    try {
        const res = await fetch(`${API_URL}/user/info`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Token: token,
                UserId: userId,
            },
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            const response = {
                Message: json.Message,
                Status: res.status,
            };
        }
    } catch (e) {
        throw e;
    }
}

// Nova varijanta u kojoj korisnik ne ispunjava svoje podatke na profilu
export async function updateUserInfo(data, token, userId, userType) {
    try {
        const res = await fetch(`${API_URL}/user/info`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Token: `${token}`,
                UserId: userId,
            },
            body: JSON.stringify({
                FirstName: data.firstName,
                LastName: data.lastName,
                Phone: data.phone,
                Email: data.email,
                // FirmName: data.firmName,
                // Type: data.userType,
                // OIB1: OIB1,
                // OIB2: OIB2,
                // Address: data.address,
                // ZipCode: data.zipCode,
                // City: data.city,
                // DeliveryAddress: data.deliveryAddress,
                // DeliveryCity: data.deliveryCity,
                // DeliveryZipCode: data.deliveryZipCode,
            }),
        });
        if (res.status === 200) {
            const responseData = await res.json();
            return responseData;
        } else {
            const json = await res.json();
            const response = {
                Message: json.Message,
                Status: res.status,
            };
            throw response;
        }
    } catch (e) {
        throw e;
    }
}
