import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../assets/css/_colors';

const Circle = styled.div`
    width: 4px;
    height: 4px;
    background-color: ${COLORS.green};
    border-radius: 5px;
    margin: ${(props) => (props.middle ? '2px 0' : '0')};
`;

const Container = styled.div`
    margin: auto;
    margin-left: 10px;
`;

export const Dots = (props) => {
    return (
        <Container>
            <Circle></Circle>
            <Circle middle></Circle>
            <Circle></Circle>
        </Container>
    );
};
