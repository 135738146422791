import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import 'react-notifications-component/dist/theme.css';
import { COLORS } from '../../../assets/css/_colors';
import { getMasterListByClientId } from '../../../services/issue/issueMaster';
import { deleteClient } from '../../../services/user/client';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg';
import { ButtonBasic } from '../../shared/CustomButton';
import { DialogWarning } from '../../shared/Dialog';
import locationIcon from '../../../assets/images/location.svg';
import checkedIcon from '../../../assets/images/checked.svg';
import IBError from 'src/services/common/IBError';

const DeleteButton = styled(ButtonBasic)`
  border: none;
  opacity: 1;

  :hover {
    background: none;
  }

  span {
    color: ${COLORS.grayTextDark};
    margin-left: 10px;
    align-self: center;
  }
`;

const Card = styled.div`
  margin: 2px;
  padding: 5px 10px;
  align-items: center;
  background: rgb(160, 204, 160);
  /* opacity: 0.6; */
  color: white;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 85% auto;
  transition: all 0.3s ease;
  cursor: pointer;
  width: 99%;
  max-width: 500px;
  position: relative;

  .checked {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    width: 30px;
    visibility: hidden;
    transition: all 0.1s ease;
  }

  &:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2),
      0 4px 20px 0px rgba(0, 0, 0, 0.19);
  }

  .name {
    font-weight: bold;
    margin-bottom: 0;
    color: ${COLORS.grayLightBackground};
  }

  .address {
    display: inline-flex;
    img {
      margin-right: 5px;
    }
  }

  @media (max-width: 780px) {
  }
`;

const ClientCard = ({
  client,
  selectedClient,
  setSelectedClient,
  lastClient,
}) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const userId = JSON.parse(localStorage.getItem('userId'));
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const onClientDeleteClick = async () => {
    try {
      await deleteClient(token, userId, selectedClient);
      localStorage.setItem('actionMessage', 'Uspješno obrisan komitent!');
      window.location.reload(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteClient = async (clientId) => {
    try {
      await getMasterListByClientId(token, userId, clientId);
      setOpenDelete(true);
    } catch (e) {
      if (e.Status === 405) {
        setOpen(true);
      } else {
        // NOTE: more meaningful error message ?
        throw new IBError('Greška');
      }
    }
  };

  return (
    <>
      <Card onClick={() => setSelectedClient(client.Id)}>
        <div>
          <p className="name">{client.KomitName}</p>
          <div className="address">
            <img src={locationIcon} />
            <p>{client.Address}</p>
          </div>
        </div>
        {!lastClient && (
          <DeleteButton
            type="button"
            onClick={() => {
              onDeleteClient(client.Id);
            }}
          >
            <DeleteIcon
              width="18px"
              height="18px"
              fill={`${COLORS.redErrorText}`}
            />
          </DeleteButton>
        )}
        {selectedClient === client.Id ? (
          <img
            style={{
              visibility: 'visible',
            }}
            className="checked"
            src={checkedIcon}
          />
        ) : (
          <img className="checked" src={checkedIcon} />
        )}
      </Card>

      <DialogWarning
        open={open}
        onClose={() => setOpen(false)}
        title={'Upozorenje!'}
        message={
          'Niste u mogućnosti obrisati ovu lokaciju jer imate prijavljene kvarove na njoj!'
        }
      />

      <DialogWarning
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        title={'Upozorenje!'}
        message={'Jeste sigurni da želite obrisati lokaciju?'}
        onClickCancel={() => setOpenDelete(false)}
        onClickOk={onClientDeleteClick}
        cancelText={'odustani'}
        okText={'obriši'}
      />
    </>
  );
};

export default ClientCard;
